import "./App.scss";
import { introBackgroundConfig, themeConfig } from "./config/commonConfig";
import WOW from "wowjs";
import { useEffect, useState } from "react";

import {
  appliedTheme,
  appliedIntro,
  getHeader,
  handleNavClick,
} from "./SharedCode";

// ------------------ Components ------------------
import AboutUs from "./components/About";
import Services from "./components/Services";
import ProductOverview from "./components/ProductOverview";
import Footer from "./components/Footer";
import { Tooltip } from "./components/Tooltip";
import CallToAction from "./components/CallToAction";
import GetInTouch from "./components/GetInTouch";
import FAQs from "./components/FAQs";
import Pricing from "./components/Pricing";
import FullScreenVideoBgIntro from "./components/themes/fullScreen/IntroVideoBg";
import FullScreenHeader from "./components/themes/fullScreen/Header";
import FullScreenDefaultIntro from "./components/themes/fullScreen/IntroDefault";
import FullScreenImgBgIntro from "./components/themes/fullScreen/IntroImageBg";
import BottomHeader from "./components/themes/bottomHeader/Header";
import StandardMenuHeader from "./components/themes/StandardMenu/Header";
import BottomHeaderDefaultIntro from "./components/themes/bottomHeader/IntroDefault";
import BottomHeaderImgBgIntro from "./components/themes/bottomHeader/IntroImageBg";
import BottomHeaderVideoBgIntro from "./components/themes/bottomHeader/IntroVideoBg";
import StandardMenuDefaultIntro from "./components/themes/StandardMenu/IntroDefault";
import StandardMenuImgBgIntro from "./components/themes/StandardMenu/IntroImageBg";
import StandardMenuVideoBgIntro from "./components/themes/StandardMenu/IntroVideoBg";
// import Resume from "./components/Resume";
// import Portfolio from "./components/Portfolio";
// import Testimonials from "./components/Testimonials";
// import Contact from "./components/Contact";
// ------------------ Components ------------------

function CoachApp(props) {
  const { content } = props;

  const [scrollTopVisible, setScrollTopVisible] = useState(false);

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  const checkScrollTop = () => {
    let scrollTopBtn = document.getElementById("back-to-top");

    if (scrollTopBtn) {
      if (
        document.body.scrollTop > 400 ||
        document.documentElement.scrollTop > 400
      ) {
        setScrollTopVisible(true);
      } else {
        setScrollTopVisible(false);
      }
    }
  };

  if (typeof window !== "undefined") {
    window.addEventListener("scroll", checkScrollTop);
  }

  const getBottomHeaderIntro = () => {
    if (appliedIntro === introBackgroundConfig.default) {
      return <BottomHeaderDefaultIntro></BottomHeaderDefaultIntro>;
    } else if (appliedIntro === introBackgroundConfig.image) {
      return <BottomHeaderImgBgIntro></BottomHeaderImgBgIntro>;
    } else {
      return <BottomHeaderVideoBgIntro></BottomHeaderVideoBgIntro>;
    }
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        <div id="main-wrapper">
          {appliedTheme === themeConfig.BottomHeader && getBottomHeaderIntro()}
          {getHeader()}

          <div id="content" role="main">
            <FullScreenVideoBgIntro content={content}></FullScreenVideoBgIntro>
            <GetInTouch content={content}></GetInTouch>
            <Services content={content}></Services>
            <CallToAction></CallToAction>
            <AboutUs content={content}></AboutUs>
            <Pricing></Pricing>
            {/*<ProductOverview></ProductOverview>*/}
            {/*<FAQs></FAQs>*/}
          </div>
          <Footer handleNavClick={handleNavClick}></Footer>
        </div>
        {/* back to top */}
        <Tooltip text="Back to Top" placement="left">
          <span
            id="back-to-top"
            className="rounded-circle"
            style={{ display: scrollTopVisible ? "inline" : "none" }}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            <i className="fas fa-arrow-up"></i>
          </span>
        </Tooltip>
      </div>
    </>
  );
}

export default CoachApp;
