import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { Tooltip } from "./Tooltip";
import Accordion from "react-bootstrap/Accordion";

const Pricing = () => {
  const form = useRef();
  const [sendingMail, setSendingMail] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setSendingMail(true);
    emailjs
      .sendForm(
        "service_i8Fk3ms",
        "template_siFcin9",
        form.current,
        "c9HsFgGF0tFWyVnAL",
      )
      .then(
        (result) => {
          document.getElementById("contact-form").reset();
          toast.success("Message sent successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          console.log(result.text);
          setSendingMail(false);
        },
        (error) => {
          toast.error("Something went wrong!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          console.log(error.text);
          setSendingMail(false);
        },
      );
  };

  return (
    <section id="pricing" className="section bg-primary">
      <div className="container">
        {/* Heading */}
        <p className="text-center mb-2 wow fadeInUp">
          <span className="bg-white text-dark px-2">How Much?</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-2 wow fadeInUp">
          Flexible Payment
        </h2>

        <div className="mt-5">
          <h3 className="text-8 fw-200 text-center mb-5 wow fadeInDown">
            First <b>50</b> conversations for <b>FREE</b>!
          </h3>
        </div>
        {/* Heading end*/}

        <div className="row row-cols-1 row-cols-md-2 mb-3 text-center">
          <div className="col wow fadeInUp">
            <div className="card mb-4 rounded-3 shadow-sm h-100">
              <div className="card-header py-3 bg-secondary">
                <h4 className="my-0 fw-normal text-10 text-white">$4</h4>
              </div>
              <div className="card-body">
                <h1 className="card-title pricing-card-title text-7 fw-600">
                  100 <small className="fw-dark">Conversations</small>
                  {/*<small className="text-muted fw-light"> / Month</small>*/}
                </h1>
                <ul className="list-unstyled mt-3 mb-4">
                  <li className="mt-1">
                    Chat for over <b>2 weeks</b>
                  </li>
                  <li className="mt-1">40-language support</li>
                  <li className="mt-1">Ask anything</li>

                  <li className="mt-1">Chat on WhatsApp & Telegram</li>
                  <li className="mt-1">Personalized AI coaching</li>
                  <li className="mt-1">Private & secure chat</li>
                  <li className="mt-1">AI proactive check-ins</li>
                </ul>
                {/*<button type="button" className="w-100 btn btn-lg btn-primary">Get started</button>*/}
              </div>
            </div>
          </div>
          <div className="col wow fadeInUp">
            <div className="card mb-4 rounded-3 shadow-sm h-100">
              <div className="card-header py-3 bg-secondary">
                <h4 className="my-0 fw-normal text-10 text-white">$8</h4>
              </div>
              <div className="card-body">
                <h1 className="card-title pricing-card-title text-7 fw-600">
                  250 <small className="fw-dark">Conversations</small>
                  {/*<small className="text-muted fw-light"> / Month</small>*/}
                </h1>
                <ul className="list-unstyled mt-3 mb-4">
                  <li className="mt-1">
                    Chat for over <b>1 month</b>
                  </li>
                  <li className="mt-1">50-language support</li>
                  <li className="mt-1">Ask everything</li>

                  <li className="mt-1">Chat on WhatsApp & Telegram</li>
                  <li className="mt-1">Personalized AI coaching</li>
                  <li className="mt-1">Private & secure chat</li>
                  <li className="mt-1">AI proactive check-ins</li>

                  {/*<li className="mt-1">Voice Messages</li>*/}
                  {/*<li className="mt-1">Video Calls</li>*/}
                  {/*<li className="mt-1">Real Life Coach Sessions</li>*/}
                </ul>
                {/*<button type="button" className="w-100 btn btn-lg btn-primary">Contact us</button>*/}
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5">
          <h3 className="text-6 fw-200 text-center mb-5 wow fadeInDown">
            <b>1</b> Conversation <b>=</b> Your <b>Q</b>uestion <b>+</b> AI{" "}
            <b>A</b>nswer
          </h3>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
