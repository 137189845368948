import React from "react";

const CoachCard = ({ coach }) => {
  const {
    first_name,
    last_name,
    main_image,
    main_image_alt,
    gallery_intro,
    unique_id,
    contact,
    title,
  } = coach;

  return (
    <div className="col-12 col-sm-6 col-lg-4 mb-4 wow fadeInUp">
      <div className="card h-100 shadow rounded-5">
        <a href={`${title.toLowerCase().replace(" ", "-")}`}>
          <img
            className="card-img-top"
            style={{ borderRadius: "2rem 2rem 0 0" }}
            src={main_image}
            alt={main_image_alt}
            title={main_image_alt}
          />
        </a>
        <div className="card-body">
          <h3 className="card-title text-center text-dark">
            <span className="text-info">AI</span> {title}
          </h3>
          <br />
          <div className="d-flex justify-content-between">
            <h5 className="card-subtitle mb-2 text-secondary">{`${first_name} ${last_name}`}</h5>
          </div>

          <p className="card-text small text-left">
            {gallery_intro}{" "}
            <a
              href={`${title.toLowerCase().replace(" ", "-")}`}
              className="Button_Learn_More align-self-center custom-link-color text-decoration-none text-secondary"
              id={`Button_Learn_More_${unique_id}`}
              style={{ fontSize: "1.2em" }}
            >
              <br />
              Read Full Profile
            </a>
          </p>
        </div>
        <div className="card-footer" style={{ minHeight: "170px" }}>
          {coach.available ? (
            <>
              <div className="text-center my-4">
                <h5 className="card-subtitle mb-2 text-success text-center">
                  Start Chatting{" "}
                  <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                    For FREE
                  </span>
                </h5>
              </div>

              <div className="mt-3 d-flex flex-column flex-lg-row justify-content-around">
    {coach.contact.telegram_available ? (
        <a
            href={contact.telegram_invite_link}
            className="btn custom-btn rounded-pill mb-3 mb-lg-0 flex-fill text-center"
            target="_blank"
            rel="noreferrer"
            id={`Button_Telegram_${unique_id}`}
        >
            <i className="fab fa-telegram-plane mr-2" /> Telegram
        </a>
    ) : null}

    {coach.contact.whatsapp_available ? (
        <a
            href={contact.whatsapp_invite_link}
            className="btn custom-btn rounded-pill mb-3 mb-lg-0 flex-fill text-center"
            target="_blank"
            rel="noreferrer"
            id={`Button_Whatsapp_${unique_id}`}
        >
            <i className="fab fa-whatsapp mr-2" /> WhatsApp
        </a>
    ) : null}
</div>
            </>
          ) : (
            <div className="text-center my-4">
              <br />
              <h4 className="text-muted">COMING SOON!</h4>
              <p className="text-muted">Stay tuned for updates!</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CoachCard;
