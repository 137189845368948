import React from "react";
import { Link } from "react-scroll";
import { Parallax } from "react-parallax";
import { scrollDuration } from "../config/commonConfig";

const Technology = () => {
  return (
    <section id="CallToAction">
      <div className="hero-wrap">
        <div className="hero-mask opacity-8 bg-dark" />
        <Parallax
          bgImage="images/site_assets/parallax-image-readytochat-2.jpg"
          bgImageAlt="Intro"
          strength={200}
        >
          <div className="hero-content section">
            <div className="container text-center py-5 wow fadeInUp">
              <h2 className="text-10 fw-600 text-white mb-5">
                What Are You Waiting For?
              </h2>

              <Link
                className="btn btn-primary rounded-0 wow fadeInUp text-6"
                smooth="easeInOutQuint"
                duration={scrollDuration}
                style={{ cursor: "pointer" }}
                to="welcome"
              >
                Start Chatting For <b>Free</b>
              </Link>
            </div>
          </div>
        </Parallax>
      </div>
    </section>
  );
};

export default Technology;
