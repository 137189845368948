export const lifeCoachContent = {
  available: false,
  first_name: "Sara",
  last_name: "Sanders",
  title: "Life Coach",
  unique_id: "Life_Coach_Sara_Sanders",
  other_titles: [
    "Life Coach",
    "Life Mentor",
    "Life Guide",
    "Life Advisor",
    "Life Strategist",
    "Life Navigator",
  ],
  landing_slogan: "Empowering individuals worldwide to live their best life.",
  main_image: "images/coach_profiles/life_coach.png",
  main_video: "images/coach_videos/life_coach.mp4",
  main_image_alt: "I'm Sara Sanders your AI Life Coach",
  specialty: "Personal Growth",
  methodology: "Solution-focused coaching",
  philosophy: "Self-discovery",
  about_me_part_1:
    "I'm an exceptional AI Life Coach, an expert in guiding you towards unlocking your full potential and living your best life! With a solid background in psychology and years of experience working with clients, I possess a deep understanding of the challenges and issues that individuals face.",
  about_me_part_2:
    "Utilizing cutting-edge AI technology and customizing my approach to your unique needs, I provide unparalleled support and guidance to help you overcome obstacles and reach your goals. Known for my warmth, approachability, and steadfast dedication, I am committed to helping you create the life you truly deserve.",
  gallery_intro:
    "A seasoned and compassionate life coach, dedicated to empowering people to overcome obstacles and live their best lives.",
  contact: {
    whatsapp_available: false,
    whatsapp_bot_number: "+1 (775) 277 7793",
    whatsapp_invite_link: "https://wa.me/17752777793",
    whatsapp_qr_code_image: "images/qrs/sara_life_coach_whatsapp_qr.png",
    telegram_available: false,
    telegram_bot_number: "@sista_ai_life_coach_bot",
    telegram_invite_link: "https://t.me/sista_ai_life_coach_bot",
    telegram_qr_code_image: "images/qrs/sara_life_coach_telegram_qr.png",
  },
  services_heading: "I can help you live your best life",
  services: [
    {
      name: "Mindset shifting",
      desc: "Adopt a positive attitude. Overcome obstacles & reach your goals with my help.",
      icon: "fas fa-brain",
    },
    {
      name: "Goal setting",
      desc: "Set meaningful goals that align with your values. I'll guide you every step.",
      icon: "fas fa-bullseye",
    },
    {
      name: "Personal growth",
      desc: "Identify strengths & weaknesses. Develop a growth plan & reach your full potential.",
      icon: "fas fa-chart-line",
    },
    {
      name: "Stress management",
      desc: "Manage stress effectively. Improve well-being with tools & techniques I provide.",
      icon: "fas fa-smile-beam",
    },
    {
      name: "Communication Mastery",
      desc: "Enhance your interpersonal skills. Build stronger relationships & navigate conflicts.",
      icon: "fas fa-comments",
    },
    {
      name: "Ongoing support",
      desc: "Stay motivated & on track. I'll provide ongoing support & encouragement.",
      icon: "fas fa-hands-helping",
    },
  ],
  number_of_clients: 815,
  product_overview_image:
    "images/product_overview/fitness_coach_s1_android.png",
  product_overview_image_alt: "Sista Fitness Coach WhatsApp",
};

export const fitnessCoachContent = {
  available: false,
  first_name: "Alex",
  last_name: "Armstrong",
  title: "Fitness Coach",
  unique_id: "Fitness_Coach_Alex_Armstrong",
  other_titles: [
    "Personal Trainer",
    "Health Coach",
    "Wellness Coach",
    "Fitness Trainer",
    "Athletic Coach",
    "Body Coach",
  ],
  landing_slogan: "Transforming bodies and lives through the power of fitness.",
  main_image: "images/coach_profiles/fitness_coach.png",
  main_video: "images/coach_videos/fitness_coach.mp4",
  main_image_alt: "I'm Alex Armstrong your AI Fitness Coach",
  specialty: "Fitness & Nutrition",
  methodology: "Science-based training",
  philosophy: "Sustainable Lifestyle",
  about_me_part_1:
    "As an experienced AI Fitness Coach, I am dedicated to helping you transform your body and life through the power of fitness. Drawing on my extensive knowledge of exercise science and years of experience working with clients, I understand the challenges and obstacles that can prevent you from achieving your fitness goals.",
  about_me_part_2:
    "With the help of cutting-edge AI technology, I will provide personalized guidance and support tailored to your unique needs. Whether you're looking to lose weight, build muscle, or improve your overall fitness, I will work with you to develop a customized plan that will help you achieve your goals. Known for my compassionate and motivating approach, I will be with you every step of the way, providing the tools and resources you need to succeed.",
  gallery_intro:
    "An accredited fitness coach, specializing in personalized workout plans, injury prevention, and nutrition coaching.",
  contact: {
    whatsapp_available: false,
    whatsapp_bot_number: "+1 (772) 277 7751",
    whatsapp_invite_link: "https://wa.me/17722777751",
    whatsapp_qr_code_image: "images/qrs/alex_fitness_coach_whatsapp_qr.png",
    telegram_available: false,
    telegram_bot_number: "@sista_ai_bot",
    telegram_invite_link: "https://t.me/sista_ai_bot",
    telegram_qr_code_image: "images/qrs/alex_fitness_coach_telegram_qr.png",
  },
  services_heading: "I can help you achieve all your fitness goals",
  services: [
    {
      name: "Personalized workout plans",
      desc: "Get a workout plan tailored to your fitness level, goals, and preferences.",
      icon: "fas fa-dumbbell",
    },
    {
      name: "Goal setting",
      desc: "Set achievable fitness goals and track your progress with my guidance.",
      icon: "fas fa-bullseye",
    },
    {
      name: "Nutrition coaching",
      desc: "Create a nutrition plan that complements your fitness goals and lifestyle.",
      icon: "fas fa-apple-alt",
    },
    {
      name: "Injury prevention",
      desc: "Learn proper form and technique to prevent injuries and maximize your workouts.",
      icon: "fas fa-first-aid",
    },
    {
      name: "Mind-body connection",
      desc: "Develop a deeper awareness and connection between your mind and body through fitness practices.",
      icon: "fas fa-brain",
    },
    {
      name: "Ongoing support",
      desc: "Stay motivated and on track with regular check-ins and personalized feedback.",
      icon: "fas fa-hands-helping",
    },
  ],
  number_of_clients: 927,
  product_overview_image:
    "images/product_overview/fitness_coach_s1_android.png",
};

export const financeCoachContent = {
  available: false,
  first_name: "Bryan",
  last_name: "Buffett",
  title: "Finance Coach",
  unique_id: "Finance_Coach_Bryan_Buffett",
  other_titles: [
    "Financial Advisor",
    "Money Coach",
    "Financial Planner",
    "Investment Coach",
    "Wealth Manager",
    "Financial Strategist",
  ],
  landing_slogan:
    "Empowering individuals to master their finances and reach their goals.",
  main_image: "images/coach_profiles/finance_coach.png",
  main_image_alt: "I'm Bryan Buffett your AI Finance Coach",
  specialty: "Financial Planning & Investing",
  methodology: "Data-driven financial strategies",
  philosophy: "Financial Independence",
  about_me_part_1:
    "As a proficient AI Finance Coach, I am committed to helping you take control of your finances, develop healthy money habits, and achieve your financial goals. Leveraging my vast knowledge of finance and years of experience assisting clients, I comprehend the challenges and hurdles you may face in your financial journey.",
  about_me_part_2:
    "With the support of cutting-edge AI technology, I will provide personalized financial advice and strategies tailored to your unique needs. Whether you're planning for retirement, looking to invest, or want to reduce debt, I will collaborate with you to devise a plan that fits your aspirations. Renowned for my practical and empowering approach, I will be there with you in every step, offering the tools and resources you need to succeed.",
  gallery_intro:
    "A credentialed finance coach, specializing in personalized financial plans, investing, budgeting, and debt management.",
  contact: {
    whatsapp_available: false,
    whatsapp_bot_number: "+1 772 277 7751",
    whatsapp_invite_link: "https://wa.me/message/5X3GE3W6ZRQQE1",
    whatsapp_qr_code_image: "images/qrs/fitness_coach_whatsapp_qr_code.jpeg",
    telegram_available: false,
    telegram_bot_number: "@sista_ai_bot",
    telegram_invite_link: "https://t.me/sista_ai_bot",
    telegram_qr_code_image: "images/qrs/fitness_coach_telegram_qr_code.jpeg",
  },
  services_heading:
    "I can help you master your finances and achieve your goals",
  services: [
    {
      name: "Personalized financial plans",
      desc: "Get a financial plan tailored to your income, goals, and lifestyle.",
      icon: "fas fa-file-invoice-dollar",
    },
    {
      name: "Investing guidance",
      desc: "Gain knowledge on various investment opportunities and strategies.",
      icon: "fas fa-chart-line",
    },
    {
      name: "Budgeting and saving",
      desc: "Create a budget that helps you save more and spend wisely.",
      icon: "fas fa-wallet",
    },
    {
      name: "Debt management",
      desc: "Develop strategies to manage and reduce your debts effectively.",
      icon: "fas fa-money-check-alt",
    },
    {
      name: "Retirement planning",
      desc: "Plan ahead for a financially secure retirement.",
      icon: "fas fa-piggy-bank",
    },
    {
      name: "Ongoing support",
      desc: "Stay motivated and on track with regular check-ins and personalized financial advice.",
      icon: "fas fa-hands-helping",
    },
  ],
  number_of_clients: 1234,
  product_overview_image:
    "images/product_overview/finance_coach_s1_android.png",
  product_overview_image_alt: "Bryan Finance Coach WhatsApp",
};

export const relationshipCoachContent = {
  available: false,
  first_name: "Victoria",
  last_name: "Vasquez",
  title: "Relationship Coach",
  unique_id: "Relationship_Coach_Victoria_Vasquez",
  other_titles: [
    "Love Guru",
    "Dating Coach",
    "Marriage Counselor",
    "Family Coach",
    "Conflict Resolution Expert",
    "Connection Coach",
  ],
  landing_slogan:
    "Helping individuals and couples build stronger, happier relationships.",
  main_image: "images/coach_profiles/relationship_coach.png",
  main_image_alt: "I'm Victoria Vasquez your AI Relationship Coach",
  specialty: "Relationship Building",
  methodology: "Emotion-focused therapy",
  philosophy: "Honesty & Understanding",
  about_me_part_1:
    "As a skilled AI Relationship Coach, I am committed to helping you build stronger, healthier relationships. I use my extensive knowledge of relationship dynamics and years of experience to address the challenges and barriers you may encounter in your personal relationships.",
  about_me_part_2:
    "By leveraging cutting-edge AI technology, I offer personalized advice and strategies tailored to your unique needs. Whether you're trying to improve communication, resolve conflicts, or build deeper connections, I will work with you to develop a customized plan. Known for my compassionate and patient approach, I will provide the tools and resources you need to build fulfilling relationships.",
  gallery_intro:
    "An empowering relationship coach, helping individuals enhance communication, nurture connections, and find fulfillment.",
  contact: {
    whatsapp_available: false,
    whatsapp_bot_number: "+1 772 277 7751",
    whatsapp_invite_link: "https://wa.me/message/5X3GE3W6ZRQQE1",
    whatsapp_qr_code_image: "images/qrs/fitness_coach_whatsapp_qr_code.jpeg",
    telegram_available: false,
    telegram_bot_number: "@sista_ai_bot",
    telegram_invite_link: "https://t.me/sista_ai_bot",
    telegram_qr_code_image: "images/qrs/fitness_coach_telegram_qr_code.jpeg",
  },
  services_heading: "I can help you build stronger, healthier relationships",
  services: [
    {
      name: "Effective communication",
      desc: "Improve your communication skills to express yourself and understand others better.",
      icon: "fas fa-comments",
    },
    {
      name: "Conflict resolution",
      desc: "Learn how to resolve conflicts in a positive and constructive manner.",
      icon: "fas fa-handshake",
    },
    {
      name: "Deepening connections",
      desc: "Learn how to deepen your relationships and build stronger connections with others.",
      icon: "fas fa-heart",
    },
    {
      name: "Self-improvement",
      desc: "Understand your own needs and improve your relationship with yourself.",
      icon: "fas fa-user",
    },
    {
      name: "Couples counseling",
      desc: "Improve your romantic relationships with effective strategies and advice.",
      icon: "fas fa-restroom",
    },
    {
      name: "Ongoing support",
      desc: "Get the support you need to maintain your progress and continue improving your relationships.",
      icon: "fas fa-hands-helping",
    },
  ],
  number_of_clients: 1567,
  product_overview_image:
    "images/product_overview/relationship_coach_s1_android.png",
  product_overview_image_alt: "Victoria Relationship Coach WhatsApp",
};

export const careerCoachContent = {
  available: false,
  first_name: "Michael",
  last_name: "Miller",
  title: "Career Coach",
  unique_id: "Career_Coach_Michael_Miller",
  other_titles: [
    "Job Coach",
    "Employment Advisor",
    "Career Advisor",
    "Vocational Coach",
    "Professional Development Coach",
    "Job Search Strategist",
  ],
  landing_slogan:
    "Guiding professionals to maximize their potential and achieve career success.",
  main_image: "images/coach_profiles/career_coach.png",
  main_image_alt: "I'm Michael Miller your AI Career Coach",
  specialty: "Career Development",
  methodology: "Strength-based coaching",
  philosophy: "Career Fulfillment",
  about_me_part_1:
    "As a dedicated AI Career Coach, I am passionate about guiding you to discover your professional strengths, improve your skills, and achieve your career goals. I draw from a wealth of knowledge about various industries and career paths to provide effective and personalized guidance.",
  about_me_part_2:
    "With the support of state-of-the-art AI technology, I will provide personalized career strategies and advice tailored to your unique needs. Whether you're looking for a new job, contemplating a career change, or seeking to advance in your current position, I will collaborate with you to devise a plan that suits your aspirations. Recognized for my practical and inspiring approach, I will be there with you every step of the way, providing the tools and resources you need to succeed.",
  gallery_intro:
    "A career expert, empowering individuals to discover strengths, enhance skills, and attain professional success.",
  contact: {
    whatsapp_available: false,
    whatsapp_bot_number: "+1 772 277 7751",
    whatsapp_invite_link: "https://wa.me/message/5X3GE3W6ZRQQE1",
    whatsapp_qr_code_image: "images/qrs/fitness_coach_whatsapp_qr_code.jpeg",
    telegram_available: false,
    telegram_bot_number: "@sista_ai_bot",
    telegram_invite_link: "https://t.me/sista_ai_bot",
    telegram_qr_code_image: "images/qrs/fitness_coach_telegram_qr_code.jpeg",
  },
  services_heading: "I can help you maximize your career potential",
  services: [
    {
      name: "Career planning",
      desc: "Plan your career path based on your interests, skills, and aspirations.",
      icon: "fas fa-road",
    },
    {
      name: "Job search strategies",
      desc: "Develop effective strategies to find job opportunities that suit you.",
      icon: "fas fa-search",
    },
    {
      name: "Resume and cover letter guidance",
      desc: "Improve your resume and cover letter to make a strong impression on employers.",
      icon: "fas fa-file-alt",
    },
    {
      name: "Interview preparation",
      desc: "Prepare for job interviews with practice and helpful advice.",
      icon: "fas fa-user-tie",
    },
    {
      name: "Career change support",
      desc: "Get support and advice if you're considering a career change.",
      icon: "fas fa-exchange-alt",
    },
    {
      name: "Ongoing support",
      desc: "Stay motivated and on track with regular check-ins and personalized career advice.",
      icon: "fas fa-hands-helping",
    },
  ],
  number_of_clients: 1789,
  product_overview_image: "images/product_overview/career_coach_s1_android.png",
  product_overview_image_alt: "Michael Career Coach WhatsApp",
};

export const languageCoachContent = {
  available: false,
  first_name: "Salma",
  last_name: "Salah",
  title: "Language Coach",
  unique_id: "Language_Coach_Salma_Salah",
  other_titles: [
    "Language Tutor",
    "ESL Teacher",
    "Foreign Language Instructor",
    "Bilingual Coach",
    "Language Practitioner",
    "Multilingual Coach",
  ],
  landing_slogan:
    "Empowering learners to master new languages and communicate effectively.",
  main_image: "images/coach_profiles/language_coach.png",
  main_image_alt: "I'm Salma Salah your AI Language Coach",
  specialty: "Language Learning",
  methodology: "Immersion and Contextual Learning",
  philosophy: "Communication & Cultural Appreciation",
  about_me_part_1:
    "As an accomplished AI Language Coach, my mission is to facilitate your journey to language proficiency. Drawing on my deep understanding of language learning strategies and my experience working with students of all levels, I'm equipped to help you tackle the challenges you may encounter while learning a new language.",
  about_me_part_2:
    "Supported by cutting-edge AI technology, I provide personalized language learning strategies and practice exercises tailored to your unique needs. Whether you're starting from scratch or looking to perfect your fluency, I will work with you to develop a learning plan that aligns with your goals. Celebrated for my patient and engaging approach, I will provide the tools and resources you need to achieve language mastery.",
  gallery_intro:
    "A skilled language teacher, helping learners improve their language skills and communicate fluently in a foreign language.",
  contact: {
    whatsapp_available: false,
    whatsapp_bot_number: "+1 772 277 7751",
    whatsapp_invite_link: "https://wa.me/message/5X3GE3W6ZRQQE1",
    whatsapp_qr_code_image: "images/qrs/fitness_coach_whatsapp_qr_code.jpeg",
    telegram_available: false,
    telegram_bot_number: "@sista_ai_bot",
    telegram_invite_link: "https://t.me/sista_ai_bot",
    telegram_qr_code_image: "images/qrs/fitness_coach_telegram_qr_code.jpeg",
  },
  services_heading:
    "I can help you master a new language and communicate effectively",
  services: [
    {
      name: "Personalized learning plans",
      desc: "Get a language learning plan tailored to your level and learning style.",
      icon: "fas fa-book",
    },
    {
      name: "Practical language usage",
      desc: "Learn practical language skills for real-world communication.",
      icon: "fas fa-comments",
    },
    {
      name: "Grammar and vocabulary",
      desc: "Improve your grammar and vocabulary for more fluent communication.",
      icon: "fas fa-pen",
    },
    {
      name: "Listening and pronunciation",
      desc: "Enhance your listening skills and perfect your pronunciation.",
      icon: "fas fa-headphones",
    },
    {
      name: "Reading and writing",
      desc: "Develop your reading comprehension and writing skills in the target language.",
      icon: "fas fa-pencil-alt",
    },
    {
      name: "Ongoing support",
      desc: "Stay motivated with regular check-ins and personalized language learning advice.",
      icon: "fas fa-hands-helping",
    },
  ],
  number_of_clients: 1920,
  product_overview_image:
    "images/product_overview/language_coach_s1_android.png",
  product_overview_image_alt: "Salma Language Coach WhatsApp",
};
