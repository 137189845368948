// import "./../App.scss";

import React from "react";
import { termsAndPrivacyConfig } from "./../config/commonConfig";
import PageHeader from "./PageHeader";

const Thanks = () => {
  const termsLastUpdated = termsAndPrivacyConfig.termsLastUpdated;
  return (
    <div className="row">
      <PageHeader />

      <section id="thanks" className="section bg-primary">
        <div className="container">
          <h2 className="text-14 fw-600 text-center mb-5">Thank You</h2>
          <div className="row" style={{ height: "800px" }}>
            <div className="col-lg-9 mx-auto">
              <div className="card mb-5">
                <div className="card-body">
                  <h2 className="card-title text-8 fw-600 mb-4">
                    Thank you for your purchase!
                  </h2>
                  <h4>You may now close this window.</h4>
                  <h4>
                    And return to your chat app to resume your conversation :)
                  </h4>
                  <br />
                  <p>
                    We've received your payment and your order is being
                    processed.
                    <br />
                    You will receive a text message from your coach with your
                    order details shortly.
                  </p>

                  <br />
                  <p>
                    <i>
                      If you have any questions or concerns, please don't
                      hesitate to contact us at{" "}
                      <a
                        className={"text-danger"}
                        href="mailto:support@sista.ai"
                      >
                        support@sista.ai
                      </a>
                      .
                    </i>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Thanks;
