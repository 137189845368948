import {
  appliedConfig,
  introBackgroundConfig,
  themeConfig,
} from "./config/commonConfig";
import BottomHeader from "./components/themes/bottomHeader/Header";
import FullScreenHeader from "./components/themes/fullScreen/Header";
import StandardMenuHeader from "./components/themes/StandardMenu/Header";
import StandardMenuDefaultIntro from "./components/themes/StandardMenu/IntroDefault";
import StandardMenuImgBgIntro from "./components/themes/StandardMenu/IntroImageBg";
import StandardMenuVideoBgIntro from "./components/themes/StandardMenu/IntroVideoBg";
import FullScreenDefaultIntro from "./components/themes/fullScreen/IntroDefault";
import FullScreenImgBgIntro from "./components/themes/fullScreen/IntroImageBg";
import FullScreenVideoBgIntro from "./components/themes/fullScreen/IntroVideoBg";
import React from "react";

export const appliedTheme = appliedConfig.appliedTheme;
export const appliedIntro = appliedConfig.appliedIntro;

export function handleNavClick(section) {
  document.getElementById(section).scrollIntoView({ behavior: "smooth" });
}
export function getHeader() {
  if (appliedTheme === themeConfig.BottomHeader) {
    return <BottomHeader></BottomHeader>;
  } else if (appliedTheme === themeConfig.FullScreenMenu) {
    return (
      <FullScreenHeader
        textWhite={appliedIntro === introBackgroundConfig.image}
      ></FullScreenHeader>
    );
  } else {
    return <StandardMenuHeader></StandardMenuHeader>;
  }
}

// this is not being used anymore, as I'm loading the FullScreenVideoBgIntro directly in the CoachApp.js
export function getFullScreenIntro(content) {
  if (appliedIntro === introBackgroundConfig.default) {
    return <FullScreenDefaultIntro></FullScreenDefaultIntro>;
  } else if (appliedIntro === introBackgroundConfig.image) {
    return <FullScreenImgBgIntro></FullScreenImgBgIntro>;
  } else {
    return <FullScreenVideoBgIntro content={content}></FullScreenVideoBgIntro>;
  }
}

export function getStandardMenuIntro() {
  if (appliedIntro === introBackgroundConfig.default) {
    return <StandardMenuDefaultIntro></StandardMenuDefaultIntro>;
  } else if (appliedIntro === introBackgroundConfig.image) {
    return <StandardMenuImgBgIntro></StandardMenuImgBgIntro>;
  } else {
    return <StandardMenuVideoBgIntro></StandardMenuVideoBgIntro>;
  }
}

// for the slider arrows of main landing page (used in 2 sliders, to redesign the arrows)
export const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
  <button
    {...props}
    className={
      "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
    }
    aria-hidden="true"
    aria-disabled={currentSlide === 0 ? true : false}
    type="button"
  >
    <i className="fa fa-chevron-left"></i>
  </button>
);
// for the slider arrows of main landing page (used in 2 sliders, to redesign the arrows)
export const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
  <button
    {...props}
    className={
      "slick-next slick-arrow" +
      (currentSlide === slideCount - 1 ? " slick-disabled" : "")
    }
    aria-hidden="true"
    aria-disabled={currentSlide === slideCount - 1 ? true : false}
    type="button"
  >
    <i className="fa fa-chevron-right"></i>
  </button>
);
