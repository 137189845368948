import React from "react";
import videobg from "../../../videos/home.mp4";
import Typewriter from "typewriter-effect";
import { Link } from "react-scroll";
import { scrollDuration } from "../../../config/commonConfig";
import GetInTouch from "../../GetInTouch";

const FullScreenVideoBgIntro = (props) => {
  const { content } = props;

  return (
    <section id="home">
      <div className="hero-wrap">
        <div className="hero-mask opacity-8 bg-primary" />
        <div className="player hero-bg ">
          <video
            src={videobg}
            autoPlay
            muted
            loop
            style={{
              width: "100%",
              height: "100vh",
              minHeight: "100%",
              objectFit: "cover",
            }}
          ></video>
        </div>
        <div className="hero-content py-5 d-flex fullscreen">
          <div className="container my-auto py-4">
            <div className="row">
              <div className="col-lg-7 text-center text-lg-start align-self-center order-1 order-lg-0">
                <div className="wow fadeInLeftBig">
                  <h1 className="text-12 fw-400 mb-0">
                    Hi, I'm {content.first_name} 👋
                  </h1>
                  <h2 className="text-12 fw-400 mb-0">
                    your AI <b>{content.title}</b>
                  </h2>
                  <br />
                  <br />
                  <br />
                  <h2 className="text-16 fw-600 text-uppercase mb-0 ms-n1">
                    <Typewriter
                      options={{
                        strings: content.other_titles,
                        autoStart: true,
                        loop: true,
                        delay: 400,
                        deleteSpeed: 150,
                      }}
                    />
                  </h2>
                  <br />
                  <br />
                  <p className="text-6 text-dark mt-5">
                    <i>{content.landing_slogan}</i>
                  </p>
                </div>

                <div className="wow fadeInUpBig d-flex flex-column flex-lg-row justify-content-center justify-content-lg-start">
                  <Link
                    className="btn btn-dark rounded-0 smooth-scroll my-2 my-lg-0 flex-grow-1 flex-lg-grow-0"
                    smooth="easeInOutQuint"
                    duration={scrollDuration}
                    style={{ cursor: "pointer" }}
                    to="GetInTouch"
                  >
                    Ask Me Anything!
                  </Link>

                  <Link
                    className="btn btn-outline-dark rounded-0 smooth-scroll my-2 my-lg-0 ms-lg-2 flex-grow-1 flex-lg-grow-0"
                    smooth="easeInOutQuint"
                    duration={scrollDuration}
                    style={{ cursor: "pointer" }}
                    to="about"
                  >
                    My Biography
                  </Link>
                </div>

                {/*<Link*/}
                {/*  className="btn btn-link text-dark smooth-scroll mt-3"*/}
                {/*  smooth="easeInOutQuint"*/}
                {/*  duration={scrollDuration}*/}
                {/*  style={{ cursor: "pointer" }}*/}
                {/*  to="services"*/}
                {/*>*/}
                {/*  What I Do*/}
                {/*  <span className="text-4 ms-2">*/}
                {/*    <i className="far fa-arrow-alt-circle-down" />*/}
                {/*  </span>*/}
                {/*</Link>*/}
              </div>

              <div className="col-lg-5 text-center align-self-center mb-4 mb-lg-0 order-0 order-lg-1">
                <div className="bg-primary rounded-5 d-inline-block p-3 shadow-lg wow zoomIn">
                  
                  {
                    content.main_video ? (
                      <video 
                        className="img-fluid rounded-5 d-block" 
                        src={`${content.main_video}`} 
                        alt={`${content.main_image_alt}`} 
                        title={`${content.main_image_alt}`} 
                        autoPlay 
                        loop 
                        muted
                      >
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <img
                        className="img-fluid rounded-5 d-block"
                        src={`${content.main_image}`}
                        alt={`${content.main_image_alt}`}
                        title={`${content.main_image_alt}`}
                      />
                    )
                  }
                  
                </div>
              </div>
            </div>
          </div>
          <Link
            className="scroll-down-arrow text-dark smooth-scroll"
            smooth="easeInOutQuint"
            duration={scrollDuration}
            style={{ cursor: "pointer" }}
            to="about"
          >
            <span className="animated">
              <i className="fas fa-arrow-down" />
            </span>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default FullScreenVideoBgIntro;
