import React from "react";
import { Link } from "react-scroll";
import { Parallax } from "react-parallax";
import { scrollDuration, ContactConfig } from "../config/commonConfig";

const GetInTouch = (props) => {
  const { content } = props;

  return (
    <section id="GetInTouch">
      <div className="hero-wrap">
        <div className="hero-mask opacity-8 bg-dark" />
        <Parallax
          bgImage="images/site_assets/parallax-image-getintouch-3.jpg"
          bgImageAlt="Intro"
          strength={200}
        >
          <div className="hero-content section">
            <div className="container text-center py-5 wow fadeInUp">
              <h2 className="text-10 fw-600 text-white mb-5">
                Let's Chat For FREE
              </h2>
              <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-3 mb-0 text-center justify-content-center">
                <div className="col mb-3">
                  <div className="card rounded-3 shadow-sm h-100">
                    <div className="card-header py-3 bg-primary text-center">
                      <div className="row align-items-center justify-content-center">
                        <div className="col-auto text-center">
                          <img
                            className="img-fluid"
                            src="images/social_media_icons/telegram.png"
                            title="Telegram"
                            alt="Telegram"
                            style={{ maxWidth: "40%", height: "auto" }}
                          />
                        </div>
                        <div className="col-auto text-center">
                          <h4 className="my-0 fw-normal text-8 text-center">
                            Add me on <b>Telegram</b>
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="card-body d-flex flex-column justify-content-center">
                      {content.contact.telegram_available ? (
                        <>
                          <p className="card-text">
                            <a
                              target="_blank"
                              href={content.contact.telegram_invite_link}
                              className="btn btn-success rounded-0 Button_Chat_Now"
                              id={
                                "Button_Chat_Now_" + content.contact.unique_id
                              }
                            >
                              Join Now
                            </a>
                          </p>
                          <div className="mb-3">
                            <img
                              className="img-thumbnail"
                              src={`${content.contact.telegram_qr_code_image}`}
                              title="WhatsApp QR code"
                              alt="Telegram QR code"
                              style={{ width: "250px", height: "250px" }}
                            />
                          </div>
                          <p className="mb-0 text-black">
                            {content.contact.telegram_bot_number}
                          </p>
                        </>
                      ) : (
                        <>
                          <div className="text-center mb-4 p-4 rounded">
                            <h4 className="fw-bold mb-3 text-muted">
                              COMING SOON!
                            </h4>
                            <p className="mt-3 text-muted">
                              We're working on it.
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col mb-3">
                  <div className="card rounded-3 shadow-sm h-100">
                    <div className="card-header py-3 bg-primary text-center">
                      <div className="row align-items-center justify-content-center">
                        <div className="col-auto text-center">
                          <img
                            className="img-fluid"
                            src="images/social_media_icons/whatsapp.png"
                            title="WhatsApp"
                            alt="WhatsApp"
                            style={{ maxWidth: "40%", height: "auto" }}
                          />
                        </div>
                        <div className="col-auto text-center">
                          <h4 className="my-0 fw-normal text-8 text-center">
                            Add me on <b>WhatsApp</b>
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="card-body d-flex flex-column justify-content-center">
                      {content.contact.whatsapp_available ? (
                        <>
                          <p className="card-text">
                            <a
                              target="_blank"
                              href={content.contact.whatsapp_invite_link}
                              className="btn btn-success rounded-0 Button_Chat_Now"
                              id={
                                "Button_Chat_Now_" + content.contact.unique_id
                              }
                            >
                              Join Now
                            </a>
                          </p>
                          <div className="mb-3">
                            <img
                              className="img-thumbnail"
                              src={`${content.contact.whatsapp_qr_code_image}`}
                              title="WhatsApp QR code"
                              alt="WhatsApp QR code"
                              style={{ width: "250px", height: "250px" }}
                            />
                          </div>
                          <p className="mb-0 text-black">
                            {content.contact.whatsapp_bot_number}
                          </p>
                        </>
                      ) : (
                        <>
                          <div className="text-center mb-4 p-4 rounded">
                            <h4 className="fw-bold mb-3 text-muted">
                              COMING SOON!
                            </h4>
                            <p className="mt-3 text-muted">
                              We're working on it.
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Parallax>
      </div>
    </section>
  );
};

export default GetInTouch;
