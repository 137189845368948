import React from "react";
import { lifeCoachContent } from "../config/content";
const ProductOverview = () => {
  return (
    <section id="product_overview" className="section">
      <div className="container">
        {/* Heading */}
        <p className="text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">How It Looks Like?</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          Product Overview
        </h2>
        {/* Heading end*/}

        <div className="row">
          <div className="col-lg-5 col-md-12 mb-4 mb-lg-0 wow fadeInLeft">
            <img
              className="img-fluid d-block mx-auto"
              src={`${lifeCoachContent.product_overview_image}`}
              alt={`${lifeCoachContent.product_overview_image_alt}`}
              title={`${lifeCoachContent.product_overview_image_alt}`}
              style={{ maxWidth: "80%" }}
            />
          </div>
          <div className="col-lg-7 col-md-12 wow fadeInRight">
            <ul
              className="list-unstyled text-lg-4 text-md-3 mb-4 mb-md-0"
              style={{ lineHeight: "3" }}
            >
              <ul>
                <li className="mb-1">
                  <i className="fas fa-check text-primary mr-2"></i> Supports
                  40+ Languages
                </li>
                <li className="mb-1">
                  <i className="fas fa-check text-primary mr-2"></i> No Sign-Up
                  Required
                </li>
                <li className="mb-1">
                  <i className="fas fa-check text-primary mr-2"></i> 24/7
                  Availability
                </li>
                <li className="mb-1">
                  <i className="fas fa-check text-primary mr-2"></i> Flexible
                  Payment Options
                </li>
                <li className="mb-1">
                  <i className="fas fa-check text-primary mr-2"></i> Chat on
                  WhatsApp or Telegram
                </li>
                <li className="mb-1">
                  <i className="fas fa-check text-primary mr-2"></i> AI
                  Follow-Up Messages
                </li>
                <li className="mb-1">
                  <i className="fas fa-check text-primary mr-2"></i> Friendly
                  Human-Like Conversations
                </li>
                <li className="mb-1">
                  <i className="fas fa-check text-primary mr-2"></i> Coaching
                  Plans & Progress Monitoring
                </li>
                <li className="mb-1">
                  <i className="fas fa-check text-primary mr-2"></i>{" "}
                  Personalized Advice & Recommendations
                </li>
                <li className="mb-1">
                  <i className="fas fa-check text-primary mr-2"></i> Super
                  Advanced AI Technology
                </li>
                <li className="mb-1">
                  <i className="fas fa-check text-primary mr-2"></i> No
                  Commitment, Use It When You Need It
                </li>
                <li className="mb-1">
                  <i className="fas fa-check text-primary mr-2"></i> Secure and
                  Private Communication
                </li>
                <li className="mb-1">
                  <i className="fas fa-check text-primary mr-2"></i>{" "}
                  Integrations with Third-Party Apps
                </li>
                <li className="mb-1">
                  <i className="fas fa-check text-primary mr-2"></i> Highly
                  Accurate Answers
                </li>
                <li className="mb-1">
                  <i className="fas fa-check text-primary mr-2"></i> Ask
                  Anything And Everything
                </li>
                <li className="mb-1">
                  <i className="fas fa-check text-primary mr-2"></i> And Much
                  More Is Coming Soon!
                </li>
              </ul>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductOverview;
