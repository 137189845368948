import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";
// the entire website is in the App component, which imports all the other components from the components folder.
import App from "./App";
import CoachApp from "./CoachApp";
// The other pages are in the pages folder.
import Terms from "./pages/Terms";
import Thanks from "./pages/Thanks";
import Privacy from "./pages/Privacy";
import {
  lifeCoachContent,
  fitnessCoachContent,
  financeCoachContent,
  relationshipCoachContent,
  languageCoachContent,
  careerCoachContent,
} from "./config/content";

function RedirectToAPI() {
  const { id } = useParams();
  // This is for the Stripe URL shortener feature. for Sista AI App.
  useEffect(() => {
    // this is the API Gateway URL of the Sista AI Application -
    window.location.href = `https://5m96pioe98.execute-api.us-east-1.amazonaws.com/hooks/payments/${id}`;
  }, [id]); // add id to the dependency array to ensure it's captured

  return null; // this component doesn't render anything
}

const Router = () => {
  const coaches = [
    lifeCoachContent,
    fitnessCoachContent,
    financeCoachContent,
    relationshipCoachContent,
    languageCoachContent,
    careerCoachContent,
  ];
  return (
    <BrowserRouter>
      <Routes>
        {/* Landing Pages */}
        <Route path="/" element={<App />} />
        <Route path="/pay/:id" element={<RedirectToAPI />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/thanks" element={<Thanks />} />
        {/* Coaches Pages */}
        <Route
          path="/life-coach"
          element={<CoachApp coachType="life" content={lifeCoachContent} />}
        />
        <Route
          path="/fitness-coach"
          element={
            <CoachApp coachType="fitness" content={fitnessCoachContent} />
          }
        />

        {coaches.map((coach, index) => (
          <Route
            key={index}
            path={`/${coach.title.toLowerCase().replace(" ", "-")}`}
            element={<CoachApp coachType={coach.coachType} content={coach} />}
          />
        ))}
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
