// import "./../App.scss";

import React from "react";
import { Link } from "react-router-dom";
import { termsAndPrivacyConfig } from "./../config/commonConfig";
import PageHeader from "./PageHeader";

const Privacy = () => {
  const privacyLastUpdated = termsAndPrivacyConfig.privacyLastUpdated;
  return (
    <div className="row">
      <PageHeader />
      <section id="privacy" className="section bg-primary">
        <div className="container">
          <h2 className="text-14 fw-600 text-center mb-5">Privacy Policy</h2>
          <br />
          <br />
          <div className="row">
            <div className="col-lg-9 mx-auto">
              <div className="card mb-5">
                <div className="card-body">
                  <h3 className="card-title text-8 fw-600 mb-4">
                    SISTA AI - PRIVACY POLICY
                  </h3>

                  <p>
                    Welcome to Sista.AI, an Artificial Intelligence-powered
                    coach designed to assist you in achieving your personal
                    development and wellness goals (the "Service"). The Service
                    is owned and operated by Sista.AI (the "Company", "we",
                    "us", and "our"). This Privacy Policy (the "Policy")
                    explains the privacy practices for our Service. It also
                    describes the rights and options available to you concerning
                    your information. This Policy is incorporated into the
                    Service's Terms of Use and constitutes an integral part of
                    them.
                  </p>

                  <p>
                    <b className="text-6">PERSONAL DATA WE COLLECT</b>
                  </p>

                  <p>
                    <b>
                      We collect your name and in some cases the telephone
                      number when you first access the Service
                    </b>
                  </p>
                  <p>
                    <b>Registration:</b>
                    <br />
                    The Service is openly available to use after you send us
                    your first message via WhatsApp, Facebook Messenger,
                    Telegram, or any other supported messaging application and
                    accept our Terms of Use and this Privacy Policy. You are
                    registered to the Service. We then have access to your name
                    and in some cases your phone number, which we obtain from
                    the supported messaging applications. You may also provide
                    us with your preferred name or nickname for future
                    communications. We refer to this data as{" "}
                    <b>"Registration Information"</b>.
                  </p>

                  <p>
                    <b>
                      We collect information relating to your support requests.
                    </b>
                  </p>
                  <p>
                    <b>Support:</b>
                    <br />
                    When you contact us for customer support requests, we will
                    collect your contact details and the information included in
                    your request and in follow-up interactions we have with you.
                    We refer to this entire data as <b>"Support Information"</b>
                    .
                  </p>

                  <p>
                    <b>
                      We collect the content you provide us when you use
                      Sista.AI.
                    </b>
                  </p>
                  <p>
                    We collect the content you upload when using Sista.AI and
                    your answers to Sista.AI's questions, which may include
                    personal data. We refer to this entire data as{" "}
                    <b>"Content Information"</b>.
                  </p>

                  <p>
                    <b>
                      You are not legally required to provide us your
                      information.
                    </b>
                  </p>
                  <p>
                    You do not have a legal duty to provide the above
                    information. However, note that you will not be able to sign
                    up for and use the Service, or contact us for support,
                    without providing the above information, and we will not be
                    able to provide you with the Service without collecting such
                    information.
                  </p>

                  <p>
                    <b>
                      We collect information relating to your use of the Service
                      and your interaction with our email messages.
                    </b>
                  </p>
                  <p>
                    When you are using the Service, we will record and collect
                    certain information in relation to your use of the Service
                    and your interaction with our text and email messages,
                    including IP address (and the general location corresponding
                    to the address). We refer to this entire information as{" "}
                    <b>"Analytics Information"</b>.
                  </p>

                  <p>
                    <b className="text-6">HOW WE USE PERSONAL DATA</b>
                  </p>

                  <p>
                    <b>
                      To operate the Service and provide its features, and to
                      prevent fraud.
                    </b>
                  </p>
                  <p>
                    We use Registration Information to verify your identity, and
                    enable you to access the Service, to operate it and provide
                    you with its features and functionality. We also use it to
                    facilitate our internal managerial, archival,
                    administrative, and audit activities. Providing us with the
                    Registration Information is necessary also to help us
                    prevent users fraudulent and unlawful uses of the Service.
                  </p>

                  <p>
                    <b>
                      To provide you with the Service's core purpose, functions,
                      and features.
                    </b>
                  </p>
                  <p>
                    We use your Content Information to enable you to use the
                    Service, and to provide you with its core purpose, features,
                    and functionality. We use the Content Information to analyze
                    your answers and determine and personalize Sista.AI's
                    response to it.
                  </p>

                  <p>
                    <b>To provide you customer support.</b>
                  </p>
                  <p>
                    We use your Registration and Support Information to provide
                    you customer support and handle your inquiries effectively.
                  </p>

                  <p>
                    <b>
                      We use Analytics and Content Information to train, develop
                      and enhance the Service. We also use it for business
                      administration and marketing purposes.
                    </b>
                  </p>
                  <p>
                    We use Analytics Information and Content Information to
                    understand how users interact with the Service so that we
                    can further train, improve, develop, and enhance it. We also
                    use Analytics Information to adjust the Service and our
                    communications to users' preferences. Where we use your
                    Content Information to train and improve the Service, we
                    will do so only after removing any information that directly
                    identifies you personally. We will use Analytics Information
                    and Content Information to understand the market in which we
                    operate in; for managerial reporting and business planning;
                    and for marketing purposes such as publishing experimental
                    reports to investors and other third parties. When we
                    process Analytics Information and Content Information for
                    the purpose of marketing, we will use only aggregated
                    de-identified information, and we will not be able to
                    directly identify the person who uses the Service.
                  </p>

                  <p>
                    <b className="text-6">
                      WHEN AND HOW WE SHARE PERSONAL DATA WITH OTHERS
                    </b>
                  </p>

                  <p>
                    <i>
                      We will not share your information with third parties,
                      except in the events listed below or when you provide us
                      your explicit and informed consent.
                    </i>
                  </p>

                  <p>
                    <b>
                      We share your information with our service providers
                      helping us operate the Service and our business.
                    </b>
                  </p>
                  <p>
                    We may process your information by using our service
                    providers who help us with the internal operations of our
                    Service and business. These companies are authorized to use
                    your personal information only as necessary to provide these
                    services to us and not for their own promotional purposes.
                    We do not sell your personal information to third parties.
                  </p>

                  <p>
                    <b>
                      We will share aggregated de-identified information with
                      investors and other third parties.
                    </b>
                  </p>
                  <p>
                    We will share your information with investors and other
                    third parties by publishing reports containing aggregated
                    de-identified statistics of users’ use of the Service. Such
                    reports will not directly identify you.
                  </p>

                  <p>
                    <b>
                      We will share your information if we are legally required
                      to do so.
                    </b>
                  </p>
                  <p>
                    We will share your information with the relevant authorities
                    if we are obligated to do so by law.
                  </p>

                  <p>
                    <b>
                      We will share your information if necessary to defend
                      Sista.AI from legal claims.
                    </b>
                  </p>
                  <p>
                    We may share your information with the relevant authorities
                    and with third parties (such as legal counsels and
                    advisors), if necessary to defend Sista.AI from legal claims
                    or if you have breached the{" "}
                    <Link className={"text-danger"} to="/terms">
                      Terms of Use,
                    </Link>{" "}
                    this Policy, or any other agreement you have with us, for
                    the purpose of handling the violation or breach.
                  </p>

                  <p>
                    <b>
                      We will share your information if the operation of the
                      Service is organized within a different framework.
                    </b>
                  </p>
                  <p>
                    If the operation of the Service is organized within a
                    different framework, or through another legal structure or
                    entity (such as due to a merger or acquisition), provided
                    that those entities agree to be bound by the provisions of
                    this Policy, with reasonably necessary changes taken into
                    consideration.
                  </p>

                  <p>
                    <b>
                      We will share your information in case of an emergency
                      concerning you.
                    </b>
                  </p>
                  <p>
                    If we need to act immediately to protect the personal safety
                    and health of our customers or the public.
                  </p>

                  <p>
                    <b className="text-6">
                      WHEN AND HOW WE SHARE PERSONAL DATA WITH OTHERS
                    </b>
                  </p>

                  <p>
                    <b>
                      We may store and process information outside your
                      territory.
                    </b>
                  </p>
                  <p>
                    The Service, by its nature as an online Service, may store
                    and process information and content in various locations
                    throughout the globe, including through cloud services. If
                    you are a resident in a jurisdiction where transfer of your
                    personal information to another jurisdiction requires your
                    consent, then you provide your express and unambiguous
                    consent to such cross-border transfer. If you are an EU
                    resident and we transfer your personal information to a
                    jurisdiction out of the EU, we will do so by using adequate
                    safeguards determined by the GDPR.
                  </p>

                  <p>
                    <b className="text-6">SECURITY AND DATA RETENTION</b>
                  </p>

                  <p>
                    <b>
                      We retain your personal information for as long as we need
                      it to operate the Service and interact with users, and
                      thereafter as needed for record-keeping matters.
                    </b>
                  </p>
                  <p>
                    We retain personal data for the duration needed to support
                    our ordinary business activities operating the Service and
                    interacting with users. Thereafter, we will continue to
                    retain your personal information as necessary to comply with
                    our legal obligations, resolve disputes, establish and
                    defend legal claims, and enforce our Terms of Use, this
                    Policy, and other agreements we have with our users.
                  </p>

                  <p>
                    <b>We implement measures to secure your Information.</b>
                  </p>
                  <p>
                    We implement measures to reduce the risks of damage, loss of
                    information, and unauthorized access or use of information.
                    However, these measures do not provide absolute information
                    security. Therefore, although efforts are made to secure
                    your personal information, it is not guaranteed, and you
                    cannot expect that the Service will be immune from
                    information security risks.
                  </p>

                  <p>
                    <b className="text-6">SECURITY AND DATA RETENTION</b>
                  </p>

                  <p>
                    <b>
                      The Company is the data controller of the personal data we
                      collect in relation to the Service.
                    </b>
                  </p>
                  <p>
                    The Company is the data controller for the purposes of the
                    personal data we collect via the Service and for the
                    performance of the Service.
                    {/*The Company is incorporated in...*/}
                  </p>

                  {/*<p>*/}
                  {/*    Our registered address is:*/}
                  {/*    ...*/}
                  {/*</p>*/}

                  <p>
                    <b>Legal basis for processing under the GDPR.</b>
                  </p>
                  <p>
                    The legal basis for collecting and processing Registration,
                    Content, and Support Information is the performance of our
                    Terms of Use agreement with you.
                    <br />
                    <br />
                    The legal basis for collecting and processing Analytics
                    Information and Content Information for the purpose of
                    training and improving the service is our legitimate
                    interests in developing, improving, and enhancing the
                    Service.
                    <br />
                    <br />
                    The legal basis for collecting and processing Analytics
                    Information and Content Information for the purpose of
                    publishing experimental reports is our legitimate interests
                    in developing and promoting our business.
                    <br />
                    <br />
                    The legal basis for processing your information for the
                    purpose of handling instances of abusive use of the Service
                    is our legitimate interests in defending and enforcing
                    against violations and breaches that are harmful to our
                    business.
                    <br />
                    <br />
                    The legal basis for processing your information where we are
                    legally required to share it, is our legitimate interests in
                    complying with mandatory legal requirements imposed on us.
                    <br />
                    <br />
                    The legal basis for processing your information in the event
                    of a change in our corporate structure is our legitimate
                    interests in our business continuity.
                  </p>

                  <p>
                    <b>
                      You have the right to access, update or delete your
                      information, obtain a copy of your information, withdraw
                      your consent, and object or restrict certain data
                      processing activities.
                    </b>
                  </p>
                  <p>
                    If you are an individual in the EU, you have the following
                    rights under the GDPR, subject to the limitations that the
                    GDPR attaches to these rights:
                  </p>
                  <p>
                    <b>Right to Access</b> your personal information that we
                    process and receive a copy of it.
                  </p>
                  <p>
                    <b>Right to Rectify</b> inaccurate personal information we
                    have concerning you and to have incomplete personal
                    information completed.
                  </p>
                  <p>
                    <b>Right to Data Portability,</b> that is, to receive the
                    personal information that you provided to us, in a
                    structured, commonly used, and machine-readable format. You
                    have the right to transmit this data to another service
                    provider. Where technically feasible, you have the right
                    that your personal information be transmitted directly from
                    us to the service provider you designate.
                  </p>
                  <p>
                    <b>Right to Object,</b> based on your particular situation,
                    to using your personal information on the basis of our
                    legitimate interest. However, we may override the objection
                    if we demonstrate compelling legitimate grounds, or for the
                    establishment, exercise or defense of legal claims. You may
                    also object at any time to the use of your personal
                    information for direct marketing purposes.
                  </p>
                  <p>
                    <b>Right to Restrict</b> the processing of your personal
                    information (except for storing it) if you contest its
                    accuracy, for a period enabling us to verify its accuracy;
                    if you believe that the processing is unlawful and you
                    oppose the erasure of the personal information and request
                    instead to restrict its use; if we no longer need the
                    personal information for the purposes outlined in this
                    Policy, but you require it to establish, exercise, or
                    defense relating to legal claims; or if you object to
                    processing, pending the verification whether our legitimate
                    grounds for processing override yours.
                  </p>
                  <p>
                    <b>Right to be Forgotten.</b> Under certain circumstances,
                    such as when the personal information is no longer necessary
                    for the purposes for which they were processed, you have the
                    right to ask us to erase your personal information. However,
                    we may still process your personal information if it is
                    necessary to comply with a legal obligation which we are
                    subject to under laws in the EU Member State, or for the
                    establishment, exercise or defense of legal claims.
                  </p>
                  <p>
                    If the legal basis for processing your personal information
                    is our consent, you may <b>Withdraw Your Consent</b> at any
                    time. If you do that, we will still process certain
                    information on a legal basis other than consent, as
                    described in the Policy. Withdrawing your consent will not
                    affect the lawfulness of data processing we carried out
                    based on your consent before such withdrawal.
                  </p>
                  <p>
                    If you wish to exercise any of the abovementioned rights,
                    contact us at{" "}
                    <a className={"text-danger"} href="mailto:support@sista.ai">
                      support@sista.ai
                    </a>
                    . We reserve the right to ask for reasonable evidence to
                    verify your identity before we provide you with information.
                    Where we are not able to provide you with information that
                    you have asked for, we will explain the reason for this.
                  </p>

                  <p>
                    <b>
                      You have a right to submit a complaint to the relevant
                      supervisory data protection authority.
                    </b>
                  </p>
                  <p>
                    Subject to applicable law, you have the right to lodge a
                    complaint with your local data protection authority. If you
                    are in the EU, then according to Article 77 of the GDPR, you
                    can lodge a complaint to the supervisory authority, in
                    particular in the Member State of your residence, place of
                    work or of an alleged infringement of the GDPR. For a list
                    of supervisory authorities in the EU, click{" "}
                    <a
                      className={"text-danger"}
                      href="https://edpb.europa.eu/about-edpb/board/members_en"
                    >
                      here
                    </a>
                    .
                  </p>
                  <p>
                    <b className="text-6">MINORS</b>
                  </p>
                  <p>
                    <b>
                      You must be at least 16 years of age to use the Service.
                    </b>
                  </p>
                  <p>
                    The Service is not intended for minors under the age of 16.
                    We do not knowingly or intentionally collect information
                    about minors under the age of 16. IF YOU ARE UNDER THE AGE
                    OF 16 YOU MAY NOT USE THE SERVICE.
                  </p>
                  <p>
                    <b className="text-6">CHANGES TO THIS PRIVACY POLICY</b>
                  </p>
                  <p>
                    <b>
                      If we change this Policy, we will provide you with notice
                      of such change.
                    </b>
                  </p>
                  <p>
                    From time to time, we may change this Policy, in which case
                    we will inform you of the updated Policy through the
                    Service’s interface or via the email address you provided
                    us, if we have its. The latest version of the Policy will
                    always be accessible through the Service.
                  </p>
                  <p>
                    <b className="text-6">CONTACT US</b>
                  </p>
                  <p>
                    You can contact us at{" "}
                    <a className={"text-danger"} href="mailto:support@sista.ai">
                      support@sista.ai
                    </a>
                  </p>
                  <p>
                    Should you have any inquiries, complaints or suggestions,
                    you may contact us. We will do our best to resolve your
                    issue in a timely manner.
                  </p>
                </div>
              </div>

              <p className="text-7 text-dark">
                <i>
                  <b>Last updated:</b> {privacyLastUpdated}
                </i>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Privacy;
