import React from "react";

const AboutCompany = () => {
  return (
    <section id="about_company" className="section">
      <div className="container">
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">About Us</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          What We Do?
        </h2>
        <div className="row wow fadeInUp">
          <div className="">
            <p className="text-4">
              Sista AI stands at the cutting edge of utilizing AI to
              revolutionize the coaching industry, offering personalized,
              AI-driven solutions for individual and organizational empowerment
              across various sectors.
              <br />
              <br />
              Our mission focuses on enhancing well-being and achieving personal
              goals through accessible, advanced AI coaching services. We
              envision a future where personalized coaching, powered by AI,
              redefines growth and guidance.
              <br />
              <br />
              We're not just about individual empowerment; we're committed to
              making a societal impact. Our efforts support the United Nations
              Sustainable Development Goals, especially SDG #3 - Good Health and
              Well-being, contributing to a healthier, happier world.
              <br />
              <br />
              Integrity, empathy, and continuous improvement are core to our
              values, driving our client interactions and fostering an
              inclusive, growth-oriented environment. At Sista AI, we're
              transforming the coaching field, making personal growth and
              well-being accessible to everyone.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center pt-5">
            <iframe
              width="800"
              height="500"
              src="https://www.youtube.com/embed/Tw1AYkwr170"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutCompany;
