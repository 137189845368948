import React from "react";
import Accordion from "react-bootstrap/Accordion";

const FAQs = () => {
  const faqsData = [
    {
      question: "Can I try AI coaching for free, before committing?",
      answer:
        "Yes, you can try AI coaching for free before committing. As a new client, you will receive free conversations to try the product. This allows you to experience the AI coaching before making a commitment. Once you have used up your free conversations, you will need to purchase credits to continue receiving coaching services.",
    },
    {
      question: "Is my personal information secure with the AI Coach?",
      answer:
        "Absolutely. Protecting your personal information is of utmost importance to us at the AI Coach. We take your privacy very seriously and employ robust security measures to ensure that your personal information is secure. You can rest assured that your information is safe with us and will only be used to provide you with the best possible coaching experience.",
    },
    {
      question: "Who can access my personal data with the AI Coach?",
      answer:
        "Your personal data will exclusively be accessed by the advanced AI technology that powers the AI Coach's coaching services, ensuring the utmost privacy and confidentiality. At no point in time will any human being read or gain access to your sensitive information. Moreover, the AI system has been specifically designed to remain unaware of your identity or phone number, maintaining a secure and anonymous environment for you to benefit from our services without any concerns.",
    },
    {
      question: "How can I purchase more conversations with the AI Coach?",
      answer:
        "Refilling your account is simple and convenient. All you have to do is type /refill (or simply /r) in the chat and we will send you a link to our payment provider, where you can securely pay with a credit card or other accepted forms of payment, to purchase more credits whenever you need them.",
    },
    {
      question: "How reliable is the AI Coach for serious life challenges?",
      answer:
        "The AI technology used by the AI Coach is based on cutting-edge advancements in artificial intelligence and natural language processing. It is designed to be highly reliable, responsive, and adaptive. However, it is important to remember that this technology is still experimental. While it can offer valuable guidance for various life challenges, for serious or complex concerns, it is advisable to consult a qualified professional or expert in the relevant field for more comprehensive and tailored advice.",
    },
    {
      question:
        "How does Sista AI ensure the quality of its coaching services?",
      answer:
        "Sista AI ensures the quality of its coaching services by leveraging advanced AI technology that has been trained on millions of books, articles, and other resources. This powerful technology enables the AI coaches, to possess an extensive knowledge base and provide expert guidance in their respective fields. Additionally, the AI Coach regularly evaluates the performance of its AI coaches and implements improvements to ensure that its coaching services remain top-notch.",
    },
    {
      question: "How does this Coach use AI technology?",
      answer:
        "An AI Coach is an AI technology that has been trained on vast sets of data, enabling it to better understand clients' needs and provide personalized guidance. This extensive training allows the AI Coach to adapt to each client's unique circumstances and offer tailored advice, tools, and techniques, ensuring a customized and effective coaching experience.",
    },
    // {
    //   "question": "When can I chat with my AI Coach?",
    //   "answer": "Your AI Coach is available 24/7 on your mobile phone, providing highly accessible and responsive coaching services. To chat with it, simply pick up your WhatsApp, or Telegram or any other supported chatting app and send a message. The AI Coach will typically respond within 30 seconds, ensuring timely support for all your coaching needs."
    // },
    // {
    //   "question": "What is Sista AI?",
    //   "answer": "Sista AI is a company that offers a range of expert coaching services, including Fitness, Financial, Health, Relationship, Career, Language, Nutrition, Mindfulness, and Entrepreneurship coaching. Sista AI uses the latest AI technology to help its clients achieve their goals and improve various aspects of their lives."
    // },
    // {
    //   "question": "What can I expect from working with the AI Coach?",
    //   "answer": "By working with the AI Coach, you can expect a transformative coaching experience tailored to your unique needs and goals. The AI Coach provides guidance in various aspects of life, offering personalized tools and techniques while ensuring ongoing support and encouragement throughout the coaching journey. You can expect to see improvements in your mindset, skills, and overall well-being, as well as a more confident and empowered approach to achieving your goals."
    // }
  ];

  return (
    <section id="faq" className="section bg-light">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">FAQ</span>
        </p>
        <h2 className="text-10 fw-600 text-dark text-center mb-5 wow fadeInUp">
          Have any questions?
        </h2>
        {/* Heading end*/}

        <div className="row gy-5">
          <div className="col-lg-6 order-1 order-lg-0 wow fadeInUp">
            <Accordion flush defaultActiveKey="0">
              {faqsData.length > 0 &&
                faqsData.map((faq, index) => (
                  <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header>{faq.question}</Accordion.Header>
                    <Accordion.Body
                      dangerouslySetInnerHTML={{ __html: faq.answer }}
                    ></Accordion.Body>
                  </Accordion.Item>
                ))}
            </Accordion>
          </div>
          <div
            className="col-lg-6 d-flex align-items-center justify-content-center order-0 order-lg-1 wow fadeIn"
            data-wow-delay="1s"
          >
            {" "}
            <img
              className="img-fluid"
              src="images/site_assets/faq.webp"
              title="Ask me anything"
              alt="Ask me anything"
            />{" "}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQs;
