import React, { useState } from "react";
import { Link } from "react-scroll";
import { scrollDuration } from "../../../config/commonConfig";
import { Tooltip } from "../../Tooltip";
import { useNavigate } from 'react-router-dom';

const FullScreenHeader = ({ textWhite }) => {
  const [stickyHeader, setStickyHeader] = useState(true);
  const [isNavModalClose, setIsNavModalClose] = useState(true);

  const checkScrollTop = () => {
    let header = document.getElementsByClassName("primary-menu");

    if (header) {
      if (
        document.body.scrollTop > 1 ||
        document.documentElement.scrollTop > 1
      ) {
        setStickyHeader(true);
      } else {
        setStickyHeader(true); // set this to false, if you want to hide the header on top by default, and only show it as you scroll down
      }
    }
  };

  if (typeof window !== "undefined") {
    window.addEventListener("scroll", checkScrollTop);
  }

  const navigate = useNavigate();

  return (
    <header id="header" className="sticky-top">
      {/* Navbar */}
      <nav
        className={
          "primary-menu navbar navbar-expand-none navbar-overlay bg-transparent border-bottom-0 text-5 fw-600 " +
          (stickyHeader ? "sticky-on-top " : " ") +
          (textWhite
            ? "navbar-overlay navbar-text-light"
            : "navbar-overlay-dark")
        }
      >
        <div className="container">
          {/* Used Logo In Coaches Pages */}
          <div
            className="logo"
            title="Sista AI"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setIsNavModalClose(true);
              navigate('/');
            }}
          >
            <img
              src="images/logos/sista-logo-200.png"
              alt="Sista AI"
            />
          </div>

          {/* <Link
            className="logo"
            title="Sista AI"
            smooth="easeInOutQuint"
            duration={scrollDuration}
            style={{ cursor: "pointer" }}
            to="/"
            onClick={(e) => {
              
              setIsNavModalClose(true);
            }}
          >
            <img
              src={
                textWhite
                  ? "images/logos/sista-logo-200.png"
                  : "images/logos/sista-logo-200.png"
              }
              alt="Sista AI"
            />
          </Link> */}
          {/* Logo End */}
          {/*<div*/}
          {/*  className={"text-3 ms-auto me-2 " + (textWhite ? "text-white" : "")}*/}
          {/*>*/}
          {/*  <span className="text-4 me-2">*/}
          {/*    <i className="fab fa-whatsapp" />*/}
          {/*  </span>*/}
          {/*  +1 415 465 9257*/}
          {/*</div>*/}
          <button
            className={
              "navbar-toggler collapsed " + (isNavModalClose ? "" : "show")
            }
            type="button"
            onClick={() => setIsNavModalClose(!isNavModalClose)}
          >
            <span />
            <span />
            <span />
          </button>
          <div
            id="header-nav"
            className={
              "collapse navbar-collapse " + (isNavModalClose ? "" : "show")
            }
          >
            <div className="d-flex flex-column h-100 align-items-center justify-content-center">
              <ul className="navbar-nav">
                {/*<li className="nav-item">*/}
                {/*  <Link*/}
                {/*    className="nav-link "*/}
                {/*    smooth="easeInOutQuint"*/}
                {/*    duration={scrollDuration}*/}
                {/*    style={{ cursor: "pointer" }}*/}
                {/*    activeClass="active"*/}
                {/*    spy*/}
                {/*    to="home"*/}
                {/*    onClick={(e) => {*/}
                {/*      e.preventDefault();*/}
                {/*      setIsNavModalClose(true);*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    My Home Page*/}
                {/*  </Link>*/}
                {/*</li>*/}
                <li className="nav-item">
                  <Link
                    className="nav-link "
                    smooth="easeInOutQuint"
                    duration={scrollDuration}
                    style={{ cursor: "pointer" }}
                    activeClass="active"
                    spy
                    to="GetInTouch"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    Let's Chat
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    className="nav-link "
                    smooth="easeInOutQuint"
                    duration={scrollDuration}
                    style={{ cursor: "pointer" }}
                    activeClass="active"
                    spy
                    to="services"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    What I offer
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    className="nav-link "
                    smooth="easeInOutQuint"
                    duration={scrollDuration}
                    style={{ cursor: "pointer" }}
                    activeClass="active"
                    spy
                    to="about"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    About Me
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    className="nav-link "
                    smooth="easeInOutQuint"
                    duration={scrollDuration}
                    style={{ cursor: "pointer" }}
                    activeClass="active"
                    spy
                    to="pricing"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    Pricing
                  </Link>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="/"
                    style={{ cursor: "pointer" }}
                  >
                    About Sista AI
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://blog.sista.ai"
                    style={{ cursor: "pointer" }}
                    target="_blank"
                  >
                    Our Blog
                  </a>
                </li>

                {/*<li className="nav-item">*/}
                {/*    <Link*/}
                {/*        className="nav-link "*/}
                {/*        smooth="easeInOutQuint"*/}
                {/*        duration={scrollDuration}*/}
                {/*        style={{ cursor: "pointer" }}*/}
                {/*        activeClass="active"*/}
                {/*        spy*/}
                {/*        to="product_overview"*/}
                {/*        onClick={(e) => {*/}
                {/*            e.preventDefault();*/}
                {/*            setIsNavModalClose(true);*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        Product Overview*/}
                {/*    </Link>*/}
                {/*</li>*/}

                {/*<li className="nav-item">*/}
                {/*  <Link*/}
                {/*      className="nav-link "*/}
                {/*      smooth="easeInOutQuint"*/}
                {/*      duration={scrollDuration}*/}
                {/*      style={{ cursor: "pointer" }}*/}
                {/*      activeClass="active"*/}
                {/*      spy*/}
                {/*      to="faq"*/}
                {/*      onClick={(e) => {*/}
                {/*        e.preventDefault();*/}
                {/*        setIsNavModalClose(true);*/}
                {/*      }}*/}
                {/*  >*/}
                {/*    Questions & Answers*/}
                {/*  </Link>*/}
                {/*</li>*/}

                {/*<li className="nav-item">*/}
                {/*  <Link*/}
                {/*    className="nav-link "*/}
                {/*    smooth="easeInOutQuint"*/}
                {/*    duration={scrollDuration}*/}
                {/*    style={{ cursor: "pointer" }}*/}
                {/*    activeClass="active"*/}
                {/*    spy*/}
                {/*    to="resume"*/}
                {/*    onClick={(e) => {*/}
                {/*      e.preventDefault();*/}
                {/*      setIsNavModalClose(true);*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    Resume*/}
                {/*  </Link>*/}
                {/*</li>*/}
                {/*<li className="nav-item">*/}
                {/*  <Link*/}
                {/*    className="nav-link "*/}
                {/*    smooth="easeInOutQuint"*/}
                {/*    duration={scrollDuration}*/}
                {/*    style={{ cursor: "pointer" }}*/}
                {/*    activeClass="active"*/}
                {/*    spy*/}
                {/*    to="portfolio"*/}
                {/*    onClick={(e) => {*/}
                {/*      e.preventDefault();*/}
                {/*      setIsNavModalClose(true);*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    Portfolio*/}
                {/*  </Link>*/}
                {/*</li>*/}
                {/*<li className="nav-item">*/}
                {/*  <Link*/}
                {/*    className="nav-link "*/}
                {/*    smooth="easeInOutQuint"*/}
                {/*    duration={scrollDuration}*/}
                {/*    style={{ cursor: "pointer" }}*/}
                {/*    activeClass="active"*/}
                {/*    spy*/}
                {/*    to="testimonial"*/}
                {/*    onClick={(e) => {*/}
                {/*      e.preventDefault();*/}
                {/*      setIsNavModalClose(true);*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    Client Speak*/}
                {/*  </Link>*/}
                {/*</li>*/}
                {/*<li className="nav-item">*/}
                {/*  <Link*/}
                {/*    className="nav-link "*/}
                {/*    smooth="easeInOutQuint"*/}
                {/*    duration={scrollDuration}*/}
                {/*    style={{ cursor: "pointer" }}*/}
                {/*    activeClass="active"*/}
                {/*    spy*/}
                {/*    to="contact"*/}
                {/*    onClick={(e) => {*/}
                {/*      e.preventDefault();*/}
                {/*      setIsNavModalClose(true);*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    Contact Me*/}
                {/*  </Link>*/}
                {/*</li>*/}
              </ul>
            </div>
          </div>
        </div>
      </nav>
      {/* Navbar End */}
    </header>
  );
};

export default FullScreenHeader;
