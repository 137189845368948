import React from "react";
import { Link } from "react-router-dom";

//  This is for the terms and conditions pages
const PageHeader = ({ textWhite }) => {
  return (
    <header
      id="pageHeader"
      className="sticky-top bg-light py-0 border-bottom border-dark"
    >
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-dark">
          <div className="container-fluid">
            <Link
              className="navbar-brand text-dark d-flex align-items-center"
              to="/"
            >
              <img src="images/logos/sista-logo-200.png" alt="Sista AI" />
            </Link>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default PageHeader;
