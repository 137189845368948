import React from "react";
import { Tooltip } from "./Tooltip";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer id="footer" className="section bg-dark text-white">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 text-center text-lg-start wow fadeInUp">
            <p className="mb-2 mb-lg-0">
              <Link className={"fw-600 text-decoration-none"} to="/terms">
                TERMS & CONDITIONS
              </Link>
              {" | "}
              <Link className={"fw-600 text-decoration-none"} to="/privacy">
                PRIVACY POLICY
              </Link>
            </p>
            <br />
            <p className="mb-2 mb-lg-0">
              Copyright © 2022-{new Date().getFullYear()}{" "}
              <Link className={" text-decoration-none"} to="/">
                Sista AI
              </Link>
              . All Rights Reserved.
            </p>
          </div>

          <div
            className="col-lg-4 text-center text-lg-start wow fadeInUp"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span>
              <img
                src="/images/logos/openai/powered-by-openai-badge-filled-on-light.svg"
                alt="Powered by OpenAI"
                style={{ width: "70%", height: "auto" }}
              />
            </span>
          </div>

          <div className="col-lg-4 wow fadeInUp">
            <div className="mb-0 text-center text-lg-end">
              <ul className="social-icons social-icons-light social-icons-lg social-icons-light d-inline-flex">
                <li className="social-icons-linkedin">
                  <Tooltip text="Linkedin" placement="top">
                    <a
                      href="https://www.linkedin.com/company/sista-ai"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </Tooltip>
                </li>
                <li className="social-icons-twitter">
                  <Tooltip text="Twitter" placement="top">
                    <a
                      href="https://twitter.com/sista_ai"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-twitter" />
                    </a>
                  </Tooltip>
                </li>
                <li className="social-icons-instagram">
                  <Tooltip text="Instagram" placement="top">
                    <a
                      href="https://www.instagram.com/sista.ai"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-instagram" />
                    </a>
                  </Tooltip>
                </li>
                <li className="social-icons-facebook">
                  <Tooltip text="Facebook" placement="top">
                    <a
                      href="https://www.facebook.com/ai.sista.fb"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-facebook" />
                    </a>
                  </Tooltip>
                </li>
                {/*<li className="social-icons-reddit">*/}
                {/*  <Tooltip text="Reddit" placement="top">*/}
                {/*    <a*/}
                {/*        href="https://www.reddit.com/r/sista_ai"*/}
                {/*        target="_blank"*/}
                {/*        rel="noopener noreferrer"*/}
                {/*    >*/}
                {/*      <i className="fab fa-reddit" />*/}
                {/*    </a>*/}
                {/*  </Tooltip>*/}
                {/*</li>*/}
                <li className="social-icons-tiktok">
                  <Tooltip text="Tiktok" placement="top">
                    <a
                      href="https://www.tiktok.com/@sista.ai"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-tiktok" />
                    </a>
                  </Tooltip>
                </li>
                <li className="social-icons-youtube">
                  <Tooltip text="Youtube" placement="top">
                    <a
                      href="https://www.youtube.com/@sista-ai"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-youtube" />
                    </a>
                  </Tooltip>
                </li>

                {/*<li className="social-icons-product-hunt">*/}
                {/*  <Tooltip text="Product Hunt" placement="top">*/}
                {/*    <a*/}
                {/*        href="https://producthunt.com/products/sista-ai"*/}
                {/*        target="_blank"*/}
                {/*        rel="noopener noreferrer"*/}
                {/*    >*/}
                {/*      <i className="fab fa-product-hunt" />*/}
                {/*    </a>*/}
                {/*  </Tooltip>*/}
                {/*</li>*/}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
