// import "./../App.scss";

import Accordion from "react-bootstrap/Accordion";
import React from "react";
import { Link } from "react-router-dom";
import { termsAndPrivacyConfig } from "./../config/commonConfig";
import PageHeader from "./PageHeader";

const Terms = () => {
  const termsLastUpdated = termsAndPrivacyConfig.termsLastUpdated;
  return (
    <div className="row">
      <PageHeader />
      <section id="terms" className="section bg-primary">
        <div className="container">
          <h2 className="text-14 fw-600 text-center mb-5">
            Terms and Conditions
          </h2>
          <br />
          <br />
          <div className="row">
            <div className="col-lg-9 mx-auto">
              <div className="card mb-5">
                <div className="card-body">
                  <h3 className="card-title text-8 fw-600 mb-4">
                    SISTA AI - TERMS OF USE – KEY POINTS
                  </h3>
                  <p>
                    The following key points of the Terms of Use are only
                    brought for your convenience. These key points do not
                    substitute the full Terms.
                  </p>

                  <b className="text-6">Introduction</b>
                  <br />
                  <p>
                    Welcome to Sista.AI, where we simplify access to AI chatbots
                    like ChatGPT by offering them as virtual assistants, such as
                    Life Coach, Financial Coach, Fitness Coach, Health Coach,
                    Relationship Coach, Career Coach, Language Coach, Nutrition
                    Coach, Mindfulness Coach, Entrepreneurship Coach, and more,
                    all available through familiar apps like WhatsApp. Our
                    mission is to empower lives through personalized AI
                    coaching, providing on-demand assistance anytime, anywhere,
                    for everyone.
                  </p>
                  <p>
                    <b>
                      Our service is not intended as, a substitute for
                      in-person, qualified professional diagnosis, advice,
                      guidance, or treatment.
                    </b>
                  </p>

                  <p>
                    At Sista.AI, we recognize that people from all walks of life
                    can benefit from tailored guidance and support, and we're
                    committed to making this a reality through our innovative
                    AI-driven solutions. Our virtual coaches are designed to
                    understand individual needs, preferences, and goals,
                    enabling them to offer truly personalized support across
                    various aspects of life, such as wellness, career, and
                    personal growth. By combining the accessibility of digital
                    platforms with the human touch of personalized coaching, we
                    strive to redefine the way people access and experience
                    personal assistance.
                  </p>
                  <p>
                    <b>
                      Please note that while our AI-powered coaching platform
                      aims to provide helpful guidance, it cannot guarantee
                      financial success, personal growth, or any specific
                      results. Users are responsible for assessing the potential
                      risks and benefits associated with the advice provided by
                      our platform and should exercise their own judgment in
                      making decisions based on this information. The Service is
                      not liable for any losses or damages resulting from
                      actions taken based on the coaching provided.
                    </b>
                  </p>
                  <p>
                    For each coaching category, it is recommended that you
                    always seek advice from a qualified professional:
                    <br />
                    <ul>
                      <li>
                        Life, Relationship, and Career: Consult a professional
                        therapist or counselor.
                      </li>
                      <li>
                        Financial: Consult a certified financial advisor or
                        planner.
                      </li>
                      <li>
                        Fitness and Nutrition: Consult a certified fitness
                        instructor, dietitian, or nutritionist.
                      </li>
                      <li>
                        Health: Consult a qualified physician or healthcare
                        professional for any medical, mental, psychological, or
                        health-related issues.
                      </li>
                      <li>
                        Language: Consult a certified language tutor or teacher.
                      </li>
                      <li>
                        Mindfulness: Consult a qualified mindfulness or
                        meditation instructor.
                      </li>
                      <li>
                        Entrepreneurship: Consult a business mentor or
                        professional advisor.
                      </li>
                    </ul>
                  </p>
                  <p>
                    <b className="text-6">Acceptable use</b>
                    <br />
                    <ul>
                      <li>
                        You may use Sista.AI for your personal and{" "}
                        <b>non-commercial use</b>.
                      </li>
                      <li>
                        These Terms define the acceptable use of the Service and
                        the actions you should avoid while using the Service.
                      </li>
                    </ul>
                    <b className="text-6">Age restriction and eligibility</b>
                    <br />
                    <ul>
                      <li>
                        The Service is intended and permitted only for
                        individuals <b>16 years</b> of age or older.
                      </li>
                      <li>
                        If you are <b>under 18</b> years old you must have your
                        parent or legal guardian’s permission to use the
                        Services. If you use the Services on behalf of another
                        person or entity, you must have the authority to accept
                        the Terms on their behalf. You must provide accurate and
                        complete information to register for an account. You may
                        not make your access credentials or account available to
                        others outside your organization, and you are
                        responsible for all activities that occur using your
                        credentials.
                      </li>
                    </ul>
                    <b className="text-6">Registration</b>
                    <br />
                    <ul>
                      <li>
                        To use the Service, simply start chatting with us on
                        your preferred platform.
                      </li>
                      <li>
                        We will create your personal User Account based on your
                        interaction.
                      </li>
                    </ul>
                    <b className="text-6">Fees and payment</b>
                    <br />
                    <ul>
                      <li>
                        The Services are subscription-based and subject to fees
                        based on the chosen subscription plan. The rates and
                        subscription plans will be posted when you start using
                        the Service in the chat.
                      </li>
                      <li>
                        You may choose to subscribe to our coaching services
                        based on your preferred subscription plan.
                      </li>
                      <li>
                        The subscription period is based on the chosen
                        subscription plan and cannot be cancelled or refunded
                        once paid for.
                      </li>
                      <li>
                        The cost of each subscription plan is based on the level
                        of access to our coaching services and the number of
                        conversations available.
                      </li>
                      <li>
                        You may purchase additional conversations beyond your
                        subscription plan at an additional cost per
                        conversation.
                      </li>
                      <li>
                        Any unused conversations or access to coaching services
                        will expire at the end of the current subscription
                        period, and no refunds will be provided for any unused
                        conversations or access.
                      </li>
                      <li>
                        If you still have unused conversations near the end of
                        the expiration period, you may choose to purchase
                        additional conversations to extend the expiration period
                        for all unused conversations.
                      </li>
                      <li>
                        Please review our Subscription Agreement for additional
                        terms and conditions regarding your subscription.
                      </li>
                    </ul>
                    <b className="text-6">Account termination</b>
                    <br />
                    <ul>
                      <li>
                        We may temporarily or permanently deny, limit, suspend,
                        or terminate your User Account at our discretion.
                      </li>
                    </ul>
                    <b className="text-6">Privacy</b>
                    <br />
                    <ul>
                      <li>
                        We respect your privacy, as further explained in our
                        Privacy Policy.
                      </li>
                    </ul>
                    <b className="text-6">Intellectual property</b>
                    <br />
                    <ul>
                      <li>
                        All rights, title, and interest in and to the Service,
                        including all intellectual property rights, are owned by
                        – or licensed to us.
                      </li>
                      <li>
                        When you upload content to the Service, you represent
                        and warrant that you are the rightful owner of all
                        rights to such Content or lawfully licensed by all the
                        rightful owners to upload such content to the Service.
                      </li>
                    </ul>
                    <b className="text-6">Availability</b>
                    <br />
                    <ul>
                      <li>
                        Sista.AI does not warrant or guarantee that the Service
                        will operate without disruption, errors or
                        interruptions, or that it will be accessible, or
                        available at all times, or immune from unauthorized
                        access or error-free.
                      </li>
                    </ul>
                    <b className="text-6">Disclaimer of warranty</b>
                    <br />
                    <ul>
                      <li>The Service is provided for use as is.</li>
                      <li>
                        We disclaim all warranties and representations, either
                        express or implied, with respect to the Service.
                      </li>
                    </ul>
                    <b className="text-6">Limitation of liability</b>
                    <br />
                    <ul>
                      <li>
                        Sista.AI, including anyone on its behalf, will not be
                        liable to the maximum extent permitted by the applicable
                        law, for any damage or loss, arising from, or in
                        connection with the use of, or the inability to use the
                        Service.
                      </li>
                    </ul>
                    <b className="text-6">Law & jurisdiction</b>
                    <br />
                    <ul>
                      <li>
                        Use of the Service is governed by the laws of the
                        jurisdiction where the Company is incorporated and
                        subject to the exclusive jurisdiction of the competent
                        courts in that jurisdiction.
                      </li>
                    </ul>
                  </p>
                </div>
              </div>

              <div className="card mb-5">
                <div className="card-body">
                  <h3 className="card-title text-8 fw-600 mb-4">
                    SISTA AI - TERMS OF USE
                  </h3>

                  <p>
                    <b className="text-6">INTRODUCTION</b>
                  </p>

                  <p>
                    Welcome to Sista.AI, an Artificial Intelligence (AI) powered
                    coach designed to assist you in achieving your personal
                    development and wellness goals (the “Service”). The Service
                    is owned and operated by Sista.AI, a company incorporated
                    under the laws of the jurisdiction where the Company is
                    incorporated (the “Company”, “we”, “us”, and “our”).
                  </p>

                  <p>
                    Please carefully read the following terms and conditions
                    (the “Terms” or the “Terms of Use”). By accessing or using
                    the Service in any way, or by interacting with our AI
                    coaches, you agree to be bound by these Terms and you
                    signify that you have read and understood them. If you do
                    not agree to the Terms, you may not use the Service in any
                    way.
                  </p>

                  <p>
                    These Terms apply to the use of the Service and any content
                    available therein by any means (including smartphones,
                    tablets, personal computers, etc.).
                  </p>

                  <p>
                    <b>
                      Use of the Service may be subject to additional terms,
                      conditions, and policies (including any privacy policies)
                      that govern the use of mobile devices and desktop devices,
                      such as the terms governing the use of Apple devices or
                      Apple’s “App Store”, the terms governing the use of the
                      Android store, or any other application marketplace, or
                      the terms governing the use of instant messaging
                      applications such as WhatsApp, Facebook Messenger,
                      Telegram, and any other supported messaging applications.
                      You bear the sole and exclusive responsibility for
                      complying with any such additionally applicable terms,
                      conditions, and policies, including those of payment
                      providers and AI chat bots, such as ChatGPT (by OpenAI).
                    </b>
                  </p>

                  <p>
                    Use of the Service requires an online connection (Wi-Fi,
                    cellular data, broadband) between your personal computer or
                    mobile device and the Internet. You will not be able to
                    access and use the Services without such a connection. You
                    are solely responsible for all costs and expenses of such
                    connection, as specified in your subscriber plan or contract
                    with your communication service provider. Some of the
                    features within the Services may be dependent on your
                    wireless service and the wireless coverage within the area
                    in which you are located at that time.
                  </p>

                  <p>
                    <b className="text-6">ABOUT THE SERVICE IN A NUTSHELL</b>
                  </p>

                  <p>
                    Sista.AI is an artificial intelligence-powered coaching
                    platform, offering a range of services such as Life Coach,
                    Financial Coach, Fitness Coach, Health Coach, Relationship
                    Coach, Career Coach, Language Coach, Nutrition Coach,
                    Mindfulness Coach, Entrepreneurship Coach, and more,
                    designed to assist you in achieving your personal
                    development and wellness goals. It is not, and is not
                    intended as, a substitute for in-person, qualified
                    professional diagnosis, advice, guidance, or treatment.
                    Always seek the advice of a qualified professional for each
                    coaching category, such as a certified financial advisor,
                    certified fitness instructor, or qualified physician,
                    depending on the specific coaching service.
                  </p>

                  <p>
                    IN CASE OF ANY EMERGENCY, CONTACT THE RELEVANT EMERGENCY
                    SERVICES IMMEDIATELY. IF YOU ARE EXPERIENCING A MENTAL
                    HEALTH CRISIS, REACH OUT TO YOUR LOCAL SUICIDE PREVENTION
                    LIFELINE FOR CONFIDENTIAL SUPPORT FOR PEOPLE IN DISTRESS.
                    FOR OTHER COACHING CATEGORIES, SEEK IMMEDIATE PROFESSIONAL
                    ASSISTANCE AS REQUIRED.
                  </p>

                  <p>
                    When creating an account with us, you will be required to
                    provide your name, and in some cases your phone number.
                    Sista.AI will then interact with you via WhatsApp, Facebook
                    Messenger, or other supported platforms, and will adapt the
                    content and timing of messages based on your preferences and
                    responses.
                  </p>

                  <p>
                    The Service may allow you to share your information with
                    others who are not using the Service. If you choose to share
                    your information with third parties through social networks
                    or any other third-party platforms, any activity or
                    communications made with your contacts on various
                    third-party platforms and social networks are made strictly
                    between you and such users/contacts. The Company is not a
                    party and does not assume any responsibility or liability
                    with respect to such communications and sharing. Use of such
                    third-party platforms is governed by their respective terms
                    of service, not these Terms. You bear the sole and exclusive
                    responsibility for complying with those other terms of
                    service.
                  </p>

                  <p>
                    <b className="text-6">AGE RESTRICTION AND ELIGIBILITY</b>
                  </p>

                  <p>
                    If you are under the age of 16, you may not use the Service
                    in any way. By using, accessing, or registering with the
                    Service, you declare that you are 16 years of age or older.
                    We reserve the right to terminate an account if we find that
                    you are younger than the minimum age specified above. We may
                    request additional information to confirm your age at any
                    time.
                  </p>

                  <p>
                    <b className="text-6">LICENSE</b>
                  </p>

                  <p>
                    Subject to these Terms, we hereby grant you a worldwide,
                    limited, revocable, non-exclusive, personal,
                    non-sub-licensable, non-transferable, non-assignable right
                    and license, until the termination or expiration of these
                    Terms or the termination of your Service account, to use the
                    Service in accordance with these Terms, for personal and
                    noncommercial use only.
                  </p>

                  <p>
                    <b className="text-6">REGISTRATION AND SERVICE ACCOUNT</b>
                  </p>

                  <p>
                    To use the Service, you must register and create a personal
                    user account by sending us a message and agreeing to these
                    Terms and to Sista AI's privacy policy (the "User Account").
                    The use of the Service is subject to payment of fees as
                    described in the "Fees and Payments" section below.
                  </p>
                  <p>
                    Once your User Account is created, Sista AI will start
                    asking you questions aimed at promoting your
                    self-development and personal state of mind.
                  </p>
                  <p>
                    You are fully accountable for any outcome that may result
                    from your failure to provide true, accurate, and complete
                    details when signing up. You are solely responsible and
                    liable for all activities performed with or through your
                    User Account.
                  </p>
                  <p>
                    You must submit only true, accurate, and complete details.
                    Keep in mind that false, incorrect, or outdated information
                    may prevent you from registering for the Service and impair
                    our ability to provide you with our Services and to contact
                    you.
                  </p>

                  <p>
                    We will explicitly indicate the fields for mandatory
                    completion. If you do not enter the requisite data in these
                    fields, you will not be able to register with the Service.
                    We will only use your personal information in accordance
                    with our Privacy Policy, which is incorporated by reference
                    into these Terms.
                  </p>
                  <p>
                    BY SUBMITTING YOUR CONTACT INFORMATION, YOU PROVIDE US WITH
                    YOUR EXPRESS CONSENT TO RECEIVE COMMERCIAL MESSAGES FROM THE
                    COMPANY OR OTHERS ON ITS BEHALF. AT ANY TIME, YOU MAY
                    WITHDRAW YOUR CONSENT AND NOTIFY US THAT YOU REFUSE TO
                    RECEIVE COMMERCIAL MESSAGES, ALTOGETHER OR OF A SPECIFIC
                    KIND, BY SENDING A NOTICE OF CONSENT WITHDRAWAL. THE
                    CHANNELS THROUGH WHICH YOU MAY SEND SUCH NOTICE WILL BE
                    DETAILED IN THE COMMERCIAL MESSAGES SENT TO YOU BY THE
                    COMPANY OR OTHERS ON ITS BEHALF.
                  </p>

                  <p>
                    You are prohibited from selling or transferring your account
                    in any way, to another user, entity, or any third party.
                  </p>

                  <p>
                    We reserve the right to request additional information to
                    verify your identity during the registration process,
                    throughout your use of the Service, or when you submit
                    requests related to your User Account on the Service. If you
                    fail to provide us with the requested information, we
                    reserve the right to suspend or terminate your User Account,
                    pursuant to these Terms.
                  </p>

                  <p>
                    <b className="text-6">
                      USER ACCOUNT SUSPENSION AND TERMINATION
                    </b>
                  </p>

                  <p>
                    In addition to any remedies that may be available to us
                    under any applicable law, we may temporarily or permanently
                    deny, limit, suspend, or terminate your User Account,
                    prohibit you from accessing the Service, and take technical
                    and legal measures to keep you off the Service if Sista AI
                    determines, at its sole discretion, that:
                  </p>

                  <p>
                    We reserve the right to suspend or terminate your User
                    Account and your access to the Service immediately and
                    without notice if:
                  </p>
                  <ul>
                    <li>You have abused your rights to use the Service; or</li>
                    <li>You have breached the Terms; or</li>
                    <li>
                      You have performed any act or omission that violates any
                      applicable law, rules, or regulations; or
                    </li>
                    <li>
                      You have performed any act or omission which is harmful or
                      likely to be harmful to the Company, or any other third
                      party, including other users and providers of the Service;
                      or
                    </li>
                    <li>
                      You used the Service to perform an illegal act, or for the
                      purpose of enabling, facilitating, assisting, or inducing
                      the performance of such an act; or
                    </li>
                    <li>
                      You have abandoned your account for more than six (6)
                      months since your last use of the Service; or
                    </li>
                    <li>
                      You deliberately submitted false information or have
                      failed to comply with our requests for information
                      regarding your identity; or
                    </li>
                    <li>
                      You have transferred your User Account to another person
                      or entity; or
                    </li>
                  </ul>
                  <p>
                    Upon termination of these Terms or your User Account, for
                    any reason:
                  </p>
                  <ul>
                    <li>
                      Your right to use the Service is terminated, and you must
                      immediately cease using the Service;
                    </li>
                    <li>
                      We reserve the right (but have no obligation) to delete
                      all of your information and account data stored on our
                      servers; and
                    </li>
                    <li>
                      We will not be liable to you or any third party for
                      termination of access to the Service or for deletion of
                      your information or account data.
                    </li>
                  </ul>
                  <p>
                    The following sections shall survive any termination or
                    expiration of the Terms: User Account Suspension and
                    Termination, Privacy, Intellectual Property, Limitation of
                    Liability, Indemnification, Application Marketplace,
                    Governing Law & Jurisdiction, General.
                  </p>

                  <p>
                    <b className="text-6">ACCEPTABLE USE OF THE SERVICE</b>
                  </p>

                  <p>
                    The following clauses define the acceptable use of the
                    Service. Subject to the Terms, you may access and use the
                    Service and the features provided through the Service, for
                    your personal and non-commercial purposes only.
                  </p>
                  <p>
                    You agree to abide by all applicable local and international
                    laws, regulations, rules, and any usage guidelines that we
                    may convey from time to time. You further agree that you are
                    solely responsible for all acts or omissions associated with
                    your access and use of the Service and the access and use of
                    the Service by anyone on your behalf. When using the
                    Service, you must refrain from:
                  </p>

                  <ul>
                    <li>
                      Breaching the Terms or any other applicable rules and
                      instructions that we may convey with respect to the use of
                      the Service;
                    </li>
                    <li>
                      Engaging in any activity that constitutes or encourages
                      conduct that would constitute a criminal offense, give
                      rise to civil liability, or otherwise violate any
                      applicable law;
                    </li>
                    <li>
                      Interfering with, burdening, or disrupting the
                      functionality of the Service;
                    </li>
                    <li>
                      Breaching the security of the Service or identifying any
                      security vulnerabilities in it;
                    </li>
                    <li>
                      Circumventing or manipulating the operation or
                      functionality of the Service, or attempting to enable
                      features or functionalities that are otherwise disabled,
                      inaccessible, or undocumented in the Service;
                    </li>
                    <li>
                      Using or launching any automated system, including robots,
                      crawlers, and similar applications to collect or compile
                      content from the Service, or in such ways that may impair
                      or disrupt the Service's functionality;
                    </li>
                    <li>
                      Displaying content from the Service, including by any
                      software, feature, gadget, or communication protocol,
                      which alters the content or its design;
                    </li>
                    <li>
                      Impersonating any person or entity, or making any false
                      statement pertaining to your identity, employment, agency,
                      or affiliation with any person or entity; or
                    </li>
                    <li>
                      Collecting, harvesting, obtaining, or processing personal
                      information of or about other users of the Service.
                    </li>
                  </ul>

                  <p>
                    You may not access or use the Service in order to develop or
                    create a similar or competitive product or service.
                  </p>
                  <p>
                    YOU ARE SOLELY RESPONSIBLE FOR THE CONTENT THAT YOU SHARE,
                    DISSEMINATE, TRANSMIT, OR OTHERWISE COMMUNICATE THROUGH, OR
                    TO THE SERVICE, OR WHEN USING THE SERVICE, AND FOR THE
                    CONSEQUENCES ASSOCIATED WITH DOING SO.
                  </p>
                  <p>
                    Without derogating from any of the above, you may not post,
                    provide, or otherwise communicate through or in connection
                    with the Service, any content which:
                  </p>
                  <ul>
                    <li>
                      May infringe rights of others, including patents,
                      copyrights, trade secrets, trademarks, a person's right to
                      privacy, or right of publicity;
                    </li>
                    <li>Is false, inaccurate, deceptive, or misleading;</li>
                    <li>
                      May include software viruses, spyware, or any other
                      malicious applications;
                    </li>
                    <li>
                      May, through posting, publication, dissemination, or
                      making available, be prohibited by any applicable law,
                      including court orders;
                    </li>
                    <li>
                      May be threatening, abusive, harassing, defamatory,
                      libelous, vulgar, violent, obscene, or racially,
                      ethnically, or otherwise objectionable;
                    </li>
                    <li>
                      May include unsolicited commercial communications
                      ('spam'), chain letters, or pyramid schemes.
                    </li>
                  </ul>
                  <p>
                    WE MAY EMPLOY TECHNOLOGICAL MEASURES TO DETECT AND PREVENT
                    FRAUDULENT OR ABUSIVE USE OF THE SERVICE. WE MAY SUSPEND OR
                    TERMINATE YOUR USER ACCOUNT, OR BLOCK YOUR ACCESS TO AND USE
                    OF THE SERVICE, WITHOUT PRIOR NOTICE AND AT OUR SOLE
                    DISCRETION, IF WE BELIEVE THAT YOU ARE USING THE SERVICE
                    FRAUDULENTLY OR ABUSIVELY.
                  </p>

                  <p>
                    <b className="text-6">PRIVACY</b>
                  </p>

                  <p>
                    We respect your privacy. <br />
                    Our{" "}
                    <Link className={"text-danger"} to="/privacy">
                      Privacy Policy
                    </Link>
                    , which is incorporated to these Terms by reference,
                    explains the privacy practices on the Service.
                  </p>

                  <p>
                    <b className="text-6">FEES AND PAYMENTS</b>
                  </p>

                  <p>
                    The Services are subject to subscription fees at the rates
                    and schemes as posted when you subscribe to the Service.
                    Subscriptions are valid for a specific period of time and
                    are billed in advance. Unused conversations will expire at
                    the end of the subscription period and cannot be carried
                    over to the next period, unless you refill your account with
                    additional conversations, in which case the unused
                    conversations from the previous period will be extended to
                    the new period. We reserve the right to change our
                    subscription rates and schemes at any time. Any changes to
                    rates or schemes will be reflected in the pricing of
                    subscriptions at the time of renewal.
                  </p>
                  <p>
                    Subscription fees can only be paid through the payment
                    methods we establish from time to time. At this time, fees
                    are charged and collected through a third-party payment
                    processing service (“Third-Party Payment Methods”). We are
                    not responsible for the terms, rules, and practices of these
                    Third-Party Payment Methods. They are subject to their own
                    terms, which you should carefully read. We may also, at any
                    time and in our sole discretion, offer free-of-charge
                    features or change a free-of-charge feature to a fee-based
                    feature. When we make any changes to the pricing for our
                    fee-based features, you will see the updated price the next
                    time you renew your subscription (A.K.A. refill your
                    account).
                  </p>
                  <p>
                    You can change your selected payment method at any time
                    using the User Account. By providing the details of your
                    chosen payment method you confirm and declare that you may
                    use the chosen payment method for the purpose of paying for
                    the Services. We may, from time to time and without notice
                    to you, add the option to use additional payment methods or
                    to discontinue the use of a payment method that we have
                    previously used.
                  </p>
                  <p>
                    We may require additional information from you before
                    completing a transaction involving payment. You undertake to
                    keep your payment details updated, complete, and accurate
                    and to notify us immediately in any case of a change in the
                    details of the chosen payment method that you have provided
                    to us.
                  </p>
                  <p>
                    In the event of a delay in payment for the Services, we may
                    stop providing the Services until your payment obligation is
                    settled and the full consideration is paid.
                  </p>
                  <p>
                    Currency and Rates. The fees are charged through the Third
                    Party Payment Methods in the currency determined by them. In
                    accordance with the rules of these Third-Party Payment
                    Methods, you may be able to pay through a different
                    currency, at your choice.
                  </p>
                  <p>
                    <b>Cancellations</b>.
                  </p>
                  <p>
                    Cancellation of a transaction for the purchase of
                    subscription plans cannot be made after the purchase is
                    completed. Unused conversations will expire at the end of
                    the current subscription period, and no refunds will be
                    provided for any unused conversations or access. However, if
                    you purchase additional conversations before the expiration
                    date, any unused conversations from the previous
                    subscription period will be carried over to the new
                    subscription period.
                  </p>
                  <p>
                    By purchasing a subscription plan through the Service, you
                    acknowledge and agree to these terms.
                  </p>

                  <p>
                    <b className="text-6">INTELLECTUAL PROPERTY</b>
                  </p>

                  <p>
                    All rights, title and interest in and to the Service (except
                    – as provided below with respect to users’ and third
                    parties’ content), including, without limitation, patents,
                    copyrights, trademarks, trade names, service marks, trade
                    secrets and other intellectual property rights, and any
                    goodwill associated therewith, are owned by, or licensed to
                    the Company.
                  </p>
                  <p>
                    Unless otherwise expressly permitted in the Terms, you may
                    not copy, distribute, display or perform publicly,
                    sublicense, decompile, disassemble, reduce to human readable
                    form, execute publicly, make available to the public, adapt,
                    make commercial use, process, compile, translate, sell,
                    lend, rent, reverse engineer, combine with other software,
                    modify or create derivative works of any of the content on
                    the Service, which is subject to intellectual property
                    rights or other proprietary rights, either by yourself or by
                    a third party on your behalf, in any way or by any means,
                    including, but not limited to electronic, mechanical or
                    optical means, without prior written authorization from the
                    Company.
                  </p>
                  <p>
                    You may not adapt or use otherwise any name, mark or logo
                    that is identical, or confusingly similar to the trademarks,
                    services marks and logos of the Company. You must refrain
                    from any action or omission which may dilute or tarnish our
                    goodwill.
                  </p>
                  <p>
                    WE DO NOT CLAIM OWNERSHIP OVER CONTENT THAT YOU SHARE
                    OTHERWISE MAKE AVAILABLE THROUGH THE SERVICE. WHEN YOU USE
                    THE SERVICE, YOU REPRESENT AND WARRANT TO US THAT –
                  </p>
                  <ul>
                    <li>
                      YOU ARE THE RIGHTFUL OWNER OF ALL RIGHTS TO SUCH CONTENT
                      OR ARE LAWFULLY LICENSED BY ALL THE RIGHTFUL OWNERS TO
                      UPLOAD, DISSEMINATE OR OTHERWISE MAKE AVAILABLE SUCH
                      CONTENT ON THE SERVICE;
                    </li>
                    <li>
                      YOU ARE LAWFULLY ENTITLED TO GRANT US THE LICENSES IN AND
                      CONSENT TO USE SUCH CONTENT, AS CONTEMPLATED BY THESE
                      TERMS AND OUR PRIVACY POLICY ;
                    </li>
                    <li>
                      USING THE CONTENT, UPLOADING, DISSEMINATING OR OTHERWISE
                      MAKING THE CONTENT AVAILABLE THROUGH THE SERVICE, DOES NOT
                      INFRINGE ON ANY RIGHTS OF THIRD PARTIES, INCLUDING
                      INTELLECTUAL PROPERTY RIGHTS, PRIVACY RIGHTS AND PUBLICITY
                      RIGHTS.
                    </li>
                  </ul>
                  <p>
                    By your use of the Service and acceptance of these Terms,
                    you hereby assign to the Company all right, title and
                    interest in and to the feedback you may have or be required
                    by the Company to provide in any circumstances regarding the
                    Service, including the right to make commercial use thereof,
                    for any purpose the Company deems appropriate. Such feedback
                    shall be deemed to include, but not limited to, information
                    about product use experience, including information
                    pertaining to bugs, errors and malfunctions of the Service,
                    performance of the Service, and the Service’s compatibility
                    and interoperability.
                  </p>

                  <p>
                    <b className="text-6">CONTENT</b>
                  </p>

                  <b className="text-4">Your Content</b>
                  <br />
                  <p>
                    You may provide input to the Services (“Input”), and receive
                    output generated and returned by the Services based on the
                    Input (“Output”). Input and Output are collectively
                    “Content.” As between the parties and to the extent
                    permitted by applicable law, you own all Input. Subject to
                    your compliance with these Terms, Sista AI hereby assigns to
                    you all its right, title and interest in and to Output. This
                    means you can use Content for any purpose, including
                    commercial purposes such as sale or publication, if you
                    comply with these Terms. Sista AI may use Content to provide
                    and maintain the Services, comply with applicable law, and
                    enforce our policies. You are responsible for Content,
                    including for ensuring that it does not violate any
                    applicable law or these Terms.
                  </p>

                  <b className="text-4">Similarity of Content</b>
                  <br />

                  <p>
                    Due to the nature of machine learning, Output may not be
                    unique across users and the Services may generate the same
                    or similar output for Sista AI or a third party. For
                    example, you may provide input to a model such as “What
                    color is the sky?” and receive output such as “The sky is
                    blue.” Other users may also ask similar questions and
                    receive the same response. Responses that are requested by
                    and generated for other users are not considered your
                    Content.
                  </p>

                  <b className="text-4">Accuracy</b>
                  <br />

                  <p>
                    Artificial intelligence and machine learning are rapidly
                    evolving fields of study. We are constantly working to
                    improve our Services to make them more accurate, reliable,
                    safe, and beneficial. Given the probabilistic nature of
                    machine learning, use of our Services may in some situations
                    result in incorrect Output that does not accurately reflect
                    real people, places, or facts. You should evaluate the
                    accuracy of any Output as appropriate for your use case,
                    including by using human review of the Output.
                  </p>

                  <p>
                    <b className="text-6">
                      CHANGES IN THE SERVICE AND DISCONTINUATION
                    </b>
                  </p>

                  <p>
                    We may, but are not obligated to, maintain the Service with
                    periodic releases of bug fixes, code updates or upgrades. We
                    will determine, in our discretion, the frequency and scope
                    of such releases and you will have no plea, claim or demand
                    against us or our Staff, for any of these releases or the
                    lack thereof.
                  </p>
                  <p>
                    You grant us your express consent to remotely send and
                    automatically install on your mobile device, without any
                    prior or additional notice, updates, upgrades, code
                    modifications, enhancements, bug fixes, improvements and any
                    other form of code or settings changes in or to the Service,
                    which, among other things, may change the its settings,
                    layout, design or display.
                  </p>
                  <p>
                    We may also, at any time and without prior notice, change
                    the layout, design, scope, features or availability of the
                    Service.
                  </p>
                  <p>
                    Such changes, by their nature, may cause inconvenience or
                    even malfunctions. YOU AGREE AND ACKNOWLEDGE THAT WE DO NOT
                    ASSUME ANY RESPONSIBILITY WITH RESPECT TO, OR IN CONNECTION
                    WITH THE INTRODUCTION OF SUCH CHANGES OR FROM ANY
                    MALFUNCTIONS OR FAILURES THAT MAY RESULT THEREFROM.
                  </p>
                  <p>
                    We may, at any time, at our sole discretion, discontinue,
                    terminate or suspend the operation of the Service, or any
                    part or aspects thereof, temporarily or permanently, without
                    any liability to you. We are not obligated to give any
                    notice prior to such change, discontinuation or suspension.
                  </p>

                  <p>
                    <b className="text-6">CONFIDENTIALITY</b>
                  </p>

                  <p>
                    You agree to regard and retain as confidential and not
                    divulge to any third party, or use for any unauthorized
                    purposes, any Confidential Information, as defined below,
                    that you acquire during your access and use of the Service,
                    without the written approval consent of the Company. Without
                    limiting the scope of this duty, you agree not to design or
                    develop any products or services, which incorporate any
                    Confidential Information. All Confidential Information
                    remains the property of the Company and no license or other
                    rights in the Confidential Information is granted hereby.
                  </p>
                  <p>
                    “Confidential Information” shall include, but will not be
                    limited to, information regarding research and development
                    related to the Service, inventions, whether patentable or
                    non-patentable, discoveries, innovations, designs, drawings,
                    sketches, diagrams, formulas, computer files, computer
                    programs, hardware, App screenshots, software, manuals,
                    selection processes, data, planning processes, trade
                    secrets, business secrets, business plans, copyrights,
                    proprietary information, processes, formulae, know-how,
                    improvements and techniques and any other data related to
                    the Service and its users. Confidential Information will
                    include information in written, oral and/or any other form
                    of communication.
                  </p>

                  <p>
                    <b className="text-6">CHANGES TO THE TERMS</b>
                  </p>

                  <p>
                    We may amend the Terms from time to time. In such cases, we
                    will notify you of the amended Terms, through the Service or
                    our Website. If you do not accept the amended Terms, we may
                    terminate the Terms and your User Account and block your
                    access to and use of the Service. Your continued use of the
                    Service after the effective date of the amended Terms
                    constitutes your consent to the amended Terms. The latest
                    version of the Terms will be accessible on our website here.
                  </p>

                  <p>
                    <b className="text-6">DISCLAIMER OF WARRANTY</b>
                  </p>

                  <p>
                    THE SERVICE IS PROVIDED FOR USE “AS IS” AND “WITH ALL
                    FAULTS”. WE AND OUR STAFF DISCLAIM ALL WARRANTIES AND
                    REPRESENTATIONS, EITHER EXPRESS OR IMPLIED, WITH RESPECT TO
                    THE SERVICE, YOUR USER ACCOUNT, ANY INTERACTION RELATED TO
                    THE SERVICE AND ANY COMMUNICATION BETWEEN YOU AND THE
                    SERVICE. WE AND OUR STAFF DISCLAIM ALL WARRANTIES OF
                    MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUALITY,
                    RELIABILITY, NON-INFRINGEMENT, TITLE, COMPATIBILITY,
                    PERFORMANCE, AVAILABILITY, SAFETY, SECURITY OR ACCURACY –
                    REGARDING THE SERVICE. YOU ACKNOWLEDGE AND AGREE THAT THE
                    USE OF THE SERVICE IS ENTIRELY, OR AT THE MAXIMUM EXTENT
                    PERMITTED BY THE APPLICABLE LAW, AT YOUR OWN RISK.
                  </p>

                  <p>
                    <b className="text-6">LIMITATION OF LIABILITY</b>
                  </p>

                  <p>
                    WE AND OUR STAFF SHALL NOT BE LIABLE TO THE MAXIMUM EXTENT
                    PERMITTED BY APPLICABLE LAW, FOR ANY DIRECT, INDIRECT,
                    SPECIAL, PUNITIVE, EXEMPLARY, STATUTORY, INCIDENTAL OR
                    CONSEQUENTIAL DAMAGE, OR ANY SIMILAR DAMAGE OR LOSS
                    (INCLUDING LOSS OF PROFIT AND LOSS OF DATA), COSTS, EXPENSES
                    AND PAYMENTS, EITHER IN TORT (INCLUDING NEGLIGENCE),
                    CONTRACT, OR IN ANY OTHER FORM OR THEORY OF LIABILITY, DEATH
                    OR BODILY INJURY, ARISING FROM, OR IN CONNECTION WITH THE
                    SERVICE, THE USE OF OR THE INABILITY TO USE THE SERVICE OR
                    YOUR USER ACCOUNT, ANY COMMUNICATION OR INTERACTIONS WITH
                    THE SERVICE OR IN CONNECTION WITH THIRD PARTY SERVICES SUCH
                    AS SOCIAL NETWORKS, YOUR RELIANCE UPON OR THE EXPECTED
                    OUTCOME FROM ANY INFORMATION AVAILABLE ON THE SERVICE, ANY
                    FAILURE, ERROR, OR BREAKDOWN IN THE FUNCTION OF THE SERVICE,
                    ANY FAULT, OR ERROR MADE BY OUR STAFF, OR FROM ANY DENIAL OR
                    CANCELATION OF YOUR USER ACCOUNT, OR FROM RETENTION,
                    DELETION, DISCLOSURE AND ANY OTHER USE OR LOSS OF YOUR
                    CONTENT ON THE SERVICE. YOU ACKNOWLEDGE AND AGREE THAT THE
                    USE OF THE SERVICE IS ENTIRELY, OR AT THE MAXIMUM EXTENT
                    PERMITTED BY THE APPLICABLE LAW, AT YOUR OWN SOLE
                    RESPONSIBILITY.
                  </p>

                  <p>
                    <b className="text-6">INDEMNIFICATION</b>
                  </p>

                  <p>
                    To the maximum extent permitted by law, you agree to
                    indemnify, defend and hold harmless, us and our Staff, at
                    your own expense and immediately after receiving a written
                    notice thereof, from and against any damages, loss, costs
                    and expenses, including attorney’s fees and legal expenses,
                    resulting from any plea, claim, allegation or demand,
                    arising from, or in connection with your use of the Service
                    or any content or Information, or from your breach of the
                    Terms. You are solely responsible for content you provide
                    and communicate through the Service, and for all
                    consequences associated with it.
                  </p>

                  <p>
                    <b className="text-6">LINKS</b>
                  </p>

                  <p>
                    The Service may contain links to content published on
                    websites or external sources, provided by third parties. We
                    do not operate or monitor these websites and content. You
                    may find them or the information and content posted therein
                    not compatible with your requirements, or you may object to
                    their content, or find such content to be annoying,
                    improper, unlawful or immoral. By linking to a certain
                    website or source, we do not endorse, or sponsor its
                    content, or confirm its accuracy, credibility, authenticity,
                    reliability, validity, integrity, or legality. We assume no
                    responsibility or liability for such third party websites or
                    content, or their availability.
                  </p>

                  <p>
                    <b className="text-6">TERMINATION OF THESE TERMS</b>
                  </p>

                  <p>
                    You may, at any time terminate these Terms and your User
                    Account by Providing us written notice of termination via
                    the Service or at{" "}
                    <a className={"text-danger"} href="mailto:support@sista.ai">
                      support@sista.ai
                    </a>
                    .
                  </p>

                  <p>
                    We may terminate these Terms and your license to use the
                    Service, at any time, by issuing you a notice of such
                    termination, blocking your access to and use of the Service,
                    or in any other manner contemplated by these Terms. Upon
                    termination by us, you must discontinue any and all use of
                    the Service.
                  </p>

                  <p>
                    Termination of these Terms will not preclude our continued
                    use of content you made available to us with a license to,
                    or any information posted by you or obtained by us while you
                    used the Service, prior to termination. In any event, our
                    continued use of such materials will be in accordance with
                    our Privacy Policy.
                  </p>

                  <p>
                    <b className="text-6">GOVERNING LAW & JURISDICTION</b>
                  </p>

                  <p>
                    Regardless of your place of residence or where you access or
                    use the Service from, these Terms and your use of the
                    Service will be governed by and construed in accordance with
                    the laws of the jurisdiction where the Company is
                    incorporated, excluding any otherwise applicable rules of
                    conflict of laws, which would result in the application of
                    the laws of a jurisdiction other than the jurisdiction where
                    the Company is incorporated.
                  </p>

                  <p>
                    The state and federal courts located in the jurisdiction
                    where the Company is incorporated will have exclusive and
                    sole jurisdiction and venue over any dispute, claim or
                    controversy arising from, or in connection with, the Service
                    and its use, and with respect to any matter relating to the
                    validity, applicability, performance or interpretation of
                    these Terms. You and the Company, each hereby expressly
                    consents to personal jurisdiction in these courts and
                    expressly waive any right to object to such personal
                    jurisdiction or the non-convenience of such forum.
                  </p>

                  <p>
                    Notwithstanding the foregoing, any claim by the Company
                    against you pursuant to the indemnity clauses of these
                    Terms, may be brought in the court in which the third party
                    claim that is the subject of the indemnity, has been lodged
                    against the Company.
                  </p>

                  <p>
                    <b className="text-6">GENERAL</b>
                  </p>

                  <p>
                    Whenever used in these Terms, the term “Including”, whether
                    capitalized or not, means without limitation to the
                    preceding phrase. All examples and e.g. notations are
                    illustrative, not exhaustive.
                  </p>

                  <p>
                    These Terms constitute the entire agreement between you and
                    us concerning the subject matter herein, and supersede all
                    prior and contemporaneous negotiations and oral
                    representations, agreements and statements.
                  </p>

                  <p>
                    No waiver, concession, extension, representation,
                    alteration, addition or derogation from the Terms by us, or
                    pursuant to the Terms, will be effective unless consented to
                    explicitly and executed in writing by our authorized
                    representative.
                  </p>

                  <p>
                    Failure on our part to demand performance of any provision
                    in the Terms shall not constitute a waiver of any of our
                    rights under the Terms.
                  </p>

                  <p>
                    You may not assign or delegate these Terms or any of your
                    rights, performances, duties or obligations hereunder. Any
                    purported assignment or delegation, in contravention of the
                    above, will be null and void. In the event of a Company
                    merger, acquisition, change of control or the sale of all or
                    substantially all of our equity or assets, we may, upon
                    notice to you and without obtaining your consent, assign and
                    delegate these Terms, in their entirety, including all of
                    our rights, performances, duties, liabilities and
                    obligations contained herein, to a third party. With such
                    assignment, transfer and delegation, we are irrevocably and
                    fully released from all rights, performance, duties,
                    liabilities and obligations under these Terms.
                  </p>
                  <p>
                    The section headings in the Terms are included for
                    convenience only and shall take no part in the
                    interpretation or construing of the Terms.
                  </p>
                  <p>
                    If any provision of the Terms is held to be illegal,
                    invalid, or unenforceable by a competent court, then the
                    provision shall be performed and enforced to the maximum
                    extent permitted by law to reflect as closely as possible,
                    the original intention of that provision, and the remaining
                    provisions of the Terms shall continue to remain in full
                    force and effect.
                  </p>
                  <p>
                    <b className="text-6">CONTACT US</b>
                  </p>
                  <p>
                    At any time, you may contact us with any question, request,
                    comment or complaint that you may have with respect to the
                    Service or these Terms, at{" "}
                    <a className={"text-danger"} href="mailto:support@sista.ai">
                      support@sista.ai
                    </a>{" "}
                    or through any of our online contact forms.
                  </p>
                  <p>
                    <b>Feedback</b>.
                    <br />
                    We appreciate feedback, comments, ideas, proposals and
                    suggestions for improvements. If you provide any of these
                    things, we may use it without restriction or compensation to
                    you.
                  </p>
                </div>
              </div>

              <p className="text-7 text-dark">
                <i>
                  <b>Last updated:</b> {termsLastUpdated}
                </i>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Terms;
