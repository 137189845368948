import React from "react";
import CoachCard from "./CoachCard";
import {
  lifeCoachContent,
  fitnessCoachContent,
  financeCoachContent,
  relationshipCoachContent,
  languageCoachContent,
  careerCoachContent,
} from "../config/content";

const Welcome = () => {
  // Set the order of the coaches on the welcome page
  const coaches = [
    lifeCoachContent,
    fitnessCoachContent,
    careerCoachContent,
    relationshipCoachContent,
    languageCoachContent,
    financeCoachContent,
  ];
  return (
    <section id="welcome" className="section bg-primary">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 text-center">
            <h2 className="text-10 fw-600 mb-4">
              Ready to transform your life?
            </h2>
            <p className="lead mb-5 fs-4">
              Choose your AI coach and start chatting for free!
            </p>
          </div>
        </div>
        <div className="row wow fadeInUpBig">
          {coaches.map((coach) => (
            <CoachCard key={coach.unique_id} coach={coach} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Welcome;
