import WOW from "wowjs";
import { useEffect } from "react";

import { handleNavClick } from "./SharedCode";

// ------------------ Components ------------------

// import Services from "./components/Services";
// import { Tooltip } from "./components/Tooltip";
// import CallToAction from "./components/CallToAction";
// import AboutUs from "./components/About";
// import GetInTouch from "./components/GetInTouch";
// import FullScreenVideoBgIntro from "./components/themes/fullScreen/IntroVideoBg";
// import FullScreenHeader from "./components/themes/fullScreen/Header";
// import FullScreenDefaultIntro from "./components/themes/fullScreen/IntroDefault";
// import FullScreenImgBgIntro from "./components/themes/fullScreen/IntroImageBg";
// import BottomHeader from "./components/themes/bottomHeader/Header";

// import BottomHeaderDefaultIntro from "./components/themes/bottomHeader/IntroDefault";
// import BottomHeaderImgBgIntro from "./components/themes/bottomHeader/IntroImageBg";
// import BottomHeaderVideoBgIntro from "./components/themes/bottomHeader/IntroVideoBg";
// import StandardMenuDefaultIntro from "./components/themes/StandardMenu/IntroDefault";
// import StandardMenuImgBgIntro from "./components/themes/StandardMenu/IntroImageBg";
// import StandardMenuVideoBgIntro from "./components/themes/StandardMenu/IntroVideoBg";
// import {themeConfig} from "./config/commonConfig";
// import Resume from "./components/Resume";
// import Portfolio from "./components/Portfolio";
// import Testimonials from "./components/Testimonials";
// import Contact from "./components/Contact";
import Footer from "./components/Footer";
import FAQs from "./components/FAQs";
import Pricing from "./components/Pricing";
import Technology from "./components/Technology";
import StandardMenuHeader from "./components/themes/StandardMenu/Header";
import Welcome from "./components/Welcome";
import AboutCompany from "./components/AboutCompany";
import ProductOverview from "./components/ProductOverview";
// ------------------ Components ------------------

function App() {
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  return (
    <>
      <div style={{ position: "relative" }}>
        <div id="main-wrapper">
          <StandardMenuHeader></StandardMenuHeader>

          <div id="content" role="main">
            <Welcome></Welcome>
            <AboutCompany></AboutCompany>
            <Pricing></Pricing>
            <ProductOverview></ProductOverview>
            <Technology></Technology>
            {/*<Testimonials></Testimonials>*/}
            <FAQs></FAQs>
            {/*<Contact></Contact>*/}
          </div>

          <Footer handleNavClick={handleNavClick}></Footer>
        </div>
      </div>
    </>
  );
}

export default App;
